/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}
.btn--squared-xs {
    width: calc(37rem/16);
    height: calc(37rem/16);
    display:inline-flex;
    justify-content:center;
    align-items:center;
    padding:0;
    text-align:center;
    text-transform: none;
}
.btn-grey {
    background-color: var(--color-light-grey);
}
.btn-grey:hover {
    background-color: var(--color-grey);
    color:#fff;
}
.btn-outline-primary:hover {
    color: #fff;
}
.btn-outline-primary.btn-outline-primary--dark {
    color: #fff;
    font-family: var(--font-default);
}
.btn-outline-primary.btn-outline-primary--dark:hover {
    color: #fff;
}
.btn-xs {
    font-size: calc(11rem/16);
    padding: calc(2rem/16);
}