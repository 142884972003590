.contact-info-box {
    background-color:#fff;
    box-shadow: 0 calc(15rem/16) calc(30rem/16) 0 rgba(31,31,31,0.05);
    padding: calc(50rem/16) calc(60rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.contact-info-box__address {
    margin-bottom: calc(10rem/16);
}
.contact-info-box__contact-list-title {
    color: var(--color-dark-grey);
    font-size: calc(12rem/16);
}
.contact-info-box__contact-link {
    color: var(--color-primary);
}
.content-info-box--transform {
   @media screen and (min-width: 768px) {
       margin-top: -30%;
   }
}