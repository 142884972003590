.word-cloud {
    padding-bottom: calc(50rem/16);
}

.word-cloud__item {
    @media (min-width: 768px){
        white-space: nowrap;
    }
    background-color: white;
    color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(25rem/16);
    font-size: calc(13rem/16);
    text-transform: uppercase;
    letter-spacing: calc(2rem/16);
    position: relative;
    border: none;
    width: 100%;
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 85%;
        height: 40%;
        transform: translateX(-50%);
        box-shadow: 0 calc(2rem/16) calc(50rem/16) rgba(0, 0, 0, 0.4);
        z-index: -1;
        transition: box-shadow 300ms ease;
    }
}

.word-cloud__item:focus,
.word-cloud__item:active {
    outline: none;
}

.word-cloud__item:hover {
    &:after {
        box-shadow: 0 calc(2rem/16) calc(50rem/16) rgba(0, 0, 0, 0.6);
    }

    .word-cloud__arrow {
        transform: translateX(5px);
    }
}

.word-cloud__item-left {
    display: flex;
    align-items: center;
    column-gap: calc(15rem/16);
    word-break: break-word;
}


.world-cloud__icon {
    font-size: calc(33rem/16);
}

.word-cloud__arrow {
    font-size: calc(11rem/16);
    transition: transform 300ms ease;
    margin-left: calc(10rem/16);
}

.word-cloud__modal__icon{
    font-size: calc(50rem / 16);
    margin-bottom: calc(20rem / 16);
    line-height: 1;
    color: #525252;
    @media screen and (min-width: 768px) {
        position: absolute;
        font-size: calc(80rem / 16);
        top: calc(66rem / 16);
        right: calc(42rem/16);
    }
    @media (min-width: 992px) {
      right: calc(128rem / 16);
    }
}

.word-cloud-modal__image img {
    width: 100%;
    height: 100%;
}

.word-cloud__cta {
    letter-spacing: calc(2rem/16);
    padding: calc(15rem/16) calc(50rem/16);
}

.word-cloud__wrapper{
    padding: inherit;
    display: flex;
    justify-content: center;
    row-gap: calc(8rem/16);
    column-gap: calc(8rem/16);
}
