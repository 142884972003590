.press-image-teaser__embed {
    padding-top: calc(321 / 482 * 100%);
}
.press-image-teaser__media {
    transition: transform 0.4s ease;
}
.press-image-teaser__embed:after {
    content:'';
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height:100%;
    background-color: rgba(55, 55, 55, 0.16);
    transition: opacity 0.3s ease;
}
.press-image-teaser:hover .press-image-teaser__embed:after {
    opacity:0;
}
.press-image-teaser:hover .press-image-teaser__media {
    transform: scale(1.04);
}
.press-image-teaser:hover .text-teaser__title {
    color: var(--color-primary);
}
.press-image-teaser__title {
    font-size: calc(18rem/16);
    font-family: var(--font-default-bold);
}
.press-image-teaser__body {
    padding: calc(20rem/16) calc(10rem/16) calc(50rem/16) calc(50rem/16) ;
    background: #fff;
    box-shadow: 0 calc(15rem/16) calc(30rem/16) 0 rgba(87,35,129,0.05);
    margin-bottom: calc(30rem/16);
    @media screen and (max-width: 1199px) {
        padding: calc(20rem/16) calc(24rem/16);
    }
}
.press-image-teaser__copyright {
    margin-bottom: calc(20rem/16);
}
.press-image-overview-teaser .text-teaser__title {
    font-size: calc(18rem/16);
    padding-top:calc(5rem/16);
    margin-bottom: calc(30rem/16);
}