.product-reload__list {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;
    @media screen and (min-width: 768px){
        flex-wrap: nowrap;
    }
}
.product-reload__item{
    width: 100%;
    padding: 0 calc(15rem/16);
    display: flex;
    flex-grow: 1;
    height: auto;
    @media screen and (min-width: 768px){
        width: 20%;
    }
}
.product-reload__item .product-teaser__body {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    @media screen and (min-width: 768px) and (max-width: 1200px){
        padding: calc(15rem / 16);
    }
}
.product-reload__item .product-teaser__title{
    font-size: calc(14rem / 16);
    line-height: 1.2;
}

.product-reload__item .product-teaser {
    display: flex;
    flex-direction: column;
}