.custom-checkbox {
    position: relative;
    padding-left: 1.7em;
}

.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
}

.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid #c4c6c7;
    width: 1.25em;
    height: 1.25em;
    margin-top: 0.12em;
    background-color:#fff;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.5em;
    color: #fff;
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box {
    background-color: var(--color-primary);
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}

/* custom checkbox switch */
.custom-checkbox--toggle-switch {
    display: flex;
    align-items: center;
    column-gap: calc(8rem/16);
}

.custom-checkbox__switch {
    position: relative;
    display: inline-block;
    width: calc(21rem/16);
    height: calc(11rem/16);
}

.custom-checkbox__switch-input {
    opacity: 0;
    width: 0;
    height: 0;
}

.custom-checkbox__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border: 1px solid var(--color-dark-grey);
    border-radius: calc(6rem/16);
    transition: background-color 300ms ease;
}

.custom-checkbox__slider:before {
    position: absolute;
    content: "";
    height: calc(7rem/16);
    width: calc(7rem/16);
    left: calc(1rem/16);
    bottom: calc(1rem/16);
    background-color: white;
    border: 1px solid var(--color-dark-grey);
    border-radius: 50%;
    transition: transform 300ms ease;
}

.custom-checkbox__switch-input:checked + .custom-checkbox__slider {
    background-color: var(--color-primary);
}

.custom-checkbox__switch-input:focus + .custom-checkbox__slider {
    box-shadow: 0 0 1px rgba(0,0,0,0.1);
}

.custom-checkbox__switch-input:checked + .custom-checkbox__slider:before {
    transform: translateX(calc(10rem/16));
}

.custom-checkbox__switch-text {
    color: var(--color-dark-grey);
    text-transform: uppercase;
    font-size: calc(13rem/16);
    line-height: calc(20rem/16);
    letter-spacing: 0;
}