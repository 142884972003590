.hotspot{
    position: absolute;
}

.hotspot__icon{
    background-color: var(--color-primary);
    border-radius: 50%;
    border: none;
    height: 2rem;
    width: 2rem;
    transition: 0.3s;
    transform: rotate(0);
    cursor: pointer;
    padding: 0;

    @media screen and (max-width: 767px){
        width: 1.4rem;
        height: 1.4rem;
    }
}

.hotspot__icon:focus {
    outline: none;
}

.hotspot__icon:before {
    display: block;
    @media screen and (max-width: 767px){
        font-size: 0.7rem;
    }
}
.show .hotspot__content{
    opacity: 1 !important;
    visibility: visible;
}

.show .hotspot__icon{
    transform: rotate(135deg) !important;
}

.hotspot__content{
    background-color: #FFF;
    box-shadow: 0 0 12px #9e9e9e;
    position: absolute;
    width: 20rem;
    bottom: 4rem;
    left: -9rem;
    opacity: 0;
    transition: 0.2s;
    visibility: hidden;
    z-index: 1;

    @media screen and (max-width: 767px){
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0 !important;
        top: 0 !important;
        box-shadow: none;
    }
}

.hotspot__content--text{
    @media screen and (max-width: 767px){
        padding: 2rem 2rem 3.2rem 2rem !important;
    }
}

.hotspot__icon--close{
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 1.8rem;
    width: 1.8rem;
    margin-left: -1rem;
    background: transparent;
    border-radius: 50%;
    border: 1.5px solid var(--color-primary);
    color: var(--color-primary);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transform: rotate(45deg);
    padding: 0;
}

.hotspot__icon--close:focus{
    outline: none;
}

.hotspot--content-bottom{
    top: 4rem !important;
    bottom: auto !important;
}

.hotspot__icon--close{
    display: none;
    @media screen and (max-width: 767px) {
        display: block;
    }
}



@media all and (min-width: 767px) and (-ms-high-contrast:none) {
    .hotspot-container-narrow.container-narrow{
        width: 47rem;
    }
}

.hotspot__wrapper {
    position: relative;
    width: fit-content;
    margin: 0 auto;
}