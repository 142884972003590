.meeting-item {
    padding-top: calc(20rem/16);
    padding-bottom: calc(30rem/16);
    border-bottom: calc(1rem/16) solid var(--color-border-grey);
    cursor:pointer;
}
.meeting-item:hover {
    border-color: var(--color-primary);
}
.meeting-item:hover .meeting-item__title {
    color: var(--color-primary);
}
.meeting-item__title {
    font-family: var(--font-default-bold);
    transition: color 0.2s ease;
    margin-bottom: calc(10rem/16);
}
.meeting-item__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: calc(28rem/16);
}
.meeting-item__legend {
    padding-bottom: calc(15rem/16);
    font-size: calc(16rem/16);
    letter-spacing: calc(1rem/16);
    border-bottom: calc(1rem/16) solid var(--color-border-grey);
}