.account-sidebar {

}

.account-sidebar__list {
    display: flex;
    flex-direction: column;
    row-gap: calc(10rem/16);
}

.account-sidebar__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: calc(74rem/16);
    background-color: var(--color-light-grey);
    padding: 0 calc(25rem/16);
    position: relative;
    border: 1px solid var(--color-light-grey);
    transition: border 300ms ease;
    @media screen and (min-width: 768px) {
        padding: 0 calc(30rem/16);
    }
}

.account-sidebar__item:hover {
    cursor: pointer;
    border: 1px solid var(--color-primary);
    & .account-sidebar__link-icon {
        transform: translateX(calc(10rem/16));
    }
}

.account-sidebar__item--active {
    border-bottom: 1px solid var(--color-primary);
    color: var(--color-primary);
}

.account-sidebar__link {
    font-family: var(--font-default-bold);
    letter-spacing: calc(.5rem/16);
}

.account-sidebar__link-icon {
    transition: transform 300ms ease;
}

.account-sidebar__status-icon {
    font-size: calc(24rem/16);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
}

.account-sidebar__incomplete-notice {
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    display: flex;
    align-items: center;
    column-gap: calc(10rem/16);
    margin-top: calc(10rem/16);
    margin-left: calc(-12rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(40rem/16);
    }
    @media screen and (min-width: 768px) {
        margin-top: calc(35rem/16);
    }
    & .icon {
        font-size: calc(24rem/16);
        color: var(--color-info);
    }
}