.cookie-first__overlay {
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    background: var(--color-grey);
}
.cookie-first__overlay__inner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.cookie-first__overlay__inner.cookie-first__overlay__inner--map {
    display: block;
   text-align: center;
    margin: calc(200rem/16) calc(20rem/16);
}