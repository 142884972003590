.link {
    color: var(--color-primary);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    line-height: 1;
}
.link--has-icon .icon{
    margin-left: calc(15rem/16);
    color: var(--color-primary);
}