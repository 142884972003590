.product-hero__compare-link {
    display: flex;
    align-items: center;
    column-gap: calc(16rem/16);
    color: var(--color-primary);
    padding: 0;
    line-height: 1;
    &:before {
        font-family: iconfont;
        content: var(--icon-compare-add);
        font-size: calc(24rem/16);
        color: var(--color-primary);
    }
}

.product-hero__compare-link.is-active {
    color: var(--color-danger);
    &:before {
        content: var(--icon-compare-remove);
        color: var(--color-danger);
    }
}

.product-hero__compare-link:disabled {
    color: var(--color-grey);
    &:before {
        color: var(--color-grey);
    }
}

.product-hero__compare-link .icon {
    font-size: calc(24rem/16);
}

.product-hero__variants-title {
    display: flex;
    align-items: center;
    column-gap: calc(8rem/16);
}

.product-hero__variants-title .icon {
    font-size: calc(24rem/16);
    color: var(--color-primary);
}

.product-hero__variants-list {
    display: flex;
    grid-gap: calc(10rem/16);
    flex-wrap: wrap;
    margin-bottom: calc(30rem/16);
}

.product-hero__login-info-text {
    font-family: var(--font-default-bold);
    line-height: calc(23rem/16);
    text-align: center;
    margin-bottom: calc(30rem/16);
}

.product-hero__login-info-text a {
    color: var(--color-primary);
    text-decoration: underline;
}

.product-hero__login-info-text .icon {
    font-size: calc(24rem/16);
    display: inline-block;
    vertical-align: middle;
    margin-left: calc(10rem/16);
}

.product-hero__enquiry-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: calc(12rem/16);
    margin-bottom: calc(5rem/16);
}

.product-hero__enquiry-btn:disabled {
    color: var(--color-dark-grey);
}

.product-hero__enquiry-btn .icon {
    font-size: calc(24rem/16);
}