.simple-badge {
    width: calc(160rem/16);
    height: calc(160rem/16);
    position: absolute;
    bottom: calc(20rem/16);
    left: calc(20rem/16);
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 calc(15rem/16) calc(30rem/16) 0 rgba(31,31,31,0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: rotate(-15deg) scale(1);
    transition: transform 300ms ease;
    z-index: 1000;
    cursor: pointer;
    padding: calc(10rem/16);
    border: calc(6rem/16) solid var(--color-primary);
    row-gap: calc(8rem/16);
    &:hover {
        transform: rotate(-30deg) scale(1.1);
    }
    @media screen and (min-width: 768px) {
        width: calc(200rem/16);
        height: calc(200rem/16);
        border: calc(8rem/16) solid var(--color-primary);
        bottom: auto;
        left: auto;
        top: 40%;
        right: calc(20rem/16);
        transform: rotate(15deg) scale(1);
        &:hover {
            transform: rotate(30deg) scale(1.1);
        }
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        top: calc(20rem/16);
    }
    @media screen and (min-width: 1200px) {
        top: calc(40rem/16);
        right: calc(40rem/16);
    }
}
.simple-badge__title {
    position: relative;
    line-height: 1;
}
.simple-badge__title:after {
    content: "";
    position: absolute;
    bottom: calc(-9rem/16);
    left: 15%;
    right: 15%;
    height: calc(2rem/16);
    background-color: var(--color-primary);
}