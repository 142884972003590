.dashboard-teaser {
    background-color: var(--color-light-grey);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: calc(30rem/16) calc(30rem/16) calc(20rem/16);
    position: relative;
    border: 1px solid var(--color-light-grey);
    transition: border 0.3s ease-in-out;
    @media screen and (min-width: 768px) {
        padding: calc(50rem/16) calc(50rem/16) calc(30rem/16);
    }
}

.dashboard-teaser:hover {
    cursor: pointer;
    border: 1px solid var(--color-primary);
    & .dashboard-teaser__link-icon {
        transform: translateX(calc(8rem/16));
    }
}

.dashboard-teaser__title {
    font-family: var(--font-default-bold);
    display: flex;
    align-items: center;
    column-gap: calc(10rem/16);
    line-height: 1;
}

.dashboard-teaser__icon {
    color: var(--color-primary);
    font-size: calc(40rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(60rem/16);
    }
}

.dashboard-teaser__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: calc(10rem/16);
}

.dashboard-teaser__link-icon {
    transition: transform 0.3s ease-in-out;
}

.dashboard-teaser__info {
    font-size: calc(24rem/16);
    border-radius: 50%;
    position: absolute;
    top: calc(20rem/16);
    right: calc(20rem/16);
    color: var(--color-primary);
    padding: calc(4rem/16);
}

.dashboard-teaser__incomplete-icon {
    font-size: calc(24rem/16);
}