.account__subtitle {
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);
}

.account__text {
    font-size: calc(14rem/16);
}

.account__spacer {
    width: 100%;
    height: calc(1rem/16);
    background-color: var(--color-border-grey);
    margin: calc(30rem/16) 0;
}

.account__change-password {
    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.account__change-password-text {
    margin-bottom: calc(10rem/16);
}

.account__change-password-link {
    text-transform: uppercase;
    color: var(--color-primary);
    display: flex;
    align-items: center;
    column-gap: calc(16rem/16);
    font-size: calc(13rem/16);
    transition: color 300ms ease;
    & .icon {
        transition: transform 300ms ease;
    }
}

.account__change-password-link:hover {
    color: var(--color-primary-dark);
    & .icon {
        transform: translateX(calc(10rem/16));
    }
}

.account__delete {
    border-top: 1px solid var(--color-light-grey);
    margin-top: calc(40rem/16);
    padding-top: calc(20rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(60rem/16);
    }
}

.account__delete-link {
    display: flex;
    align-items: center;
    column-gap: calc(16rem/16);
    font-size: calc(13rem/16);
    text-transform: uppercase;
    color: var(--color-danger);
    line-height: 1;
    transition: color 300ms ease;
    & .icon {
        font-size: calc(24rem/16);
    }
}

.account__delete-link:hover {
    color: var(--color-danger-dark);
    & .icon {
        animation: tilt-shaking 0.25s infinite;
    }
}

@keyframes tilt-shaking {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(7deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(-7deg); }
    100% { transform: rotate(0deg); }
}

/* move to form.css after FBS is ready */
.form-control:disabled {
    background-color: white;
    color: var(--color-dark-grey);
}