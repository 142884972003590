.nav-wrapper {
    box-shadow: 0 calc(15rem/16) calc(40rem/16) rgba(0,0,0,.1);
}

.main-nav__right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
}

.main-nav__right-icon {
    font-size: calc(24rem/16);
    position: relative;
    padding: calc(15rem/16);
    line-height: 1;
    background-color: white;
    border: none;
}

.main-nav__right-count {
    font-size: calc(12rem/16);
    width: calc(16rem/16);
    height: calc(16rem/16);
    color: white;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color-primary);
    border-radius: 50%;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-nav__search-btn {
    position: static;
    top: auto;
    right: auto;
    margin-left: calc(20rem/16);
    font-size: calc(32rem/16);
}

.main-nav__right-dropdown-menu {
    font-size: calc(14rem/16);
    padding: calc(10rem/16) calc(40rem/16) calc(30rem/16);
    border: none;
    box-shadow: 0 calc(15rem/16) calc(30rem/16) rgba(0,0,0,.05);
    left: auto;
    top: calc(100% + calc(24rem/16));
    right: 0;
    min-width: calc(345rem/16);
}

.main-nav__right-dropdown {
    position: relative;
}

.main-nav__right-dropdown.show .main-nav__right-icon {
    &:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        width: calc(24rem/16);
        transform: translateX(-50%);
        height: 1px;
        background-color: var(--color-primary);
    }
}

.main-nav__right-dropdown-link {
    display: block;
    font-family: var(--font-default-bold);
    margin-bottom: calc(12rem/16);
}

.main-nav__right-dropdown-logout {
    display: block;
    margin-top: calc(24rem/16);
}