.order-info-card {
    padding: calc(30rem/16) calc(40rem/16);
    background-color: var(--color-light-grey);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: calc(30rem/16);
    @media screen and (max-width: 1199px) {
        row-gap: calc(16rem/16);
    }
    @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.order-info-card--two-col {
    grid-template-columns: repeat(2, 1fr);
}

.order-info-card__title {
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(10rem/16);
    letter-spacing: 0;
}

.order-info-card__address {
    margin-bottom: 0;
}

.order-info-card__edit-link {
    display: flex;
    align-self: flex-start;
    color: var(--color-primary);
    text-transform: uppercase;
    align-items: center;
    column-gap: calc(10rem/16);
    justify-self: flex-end;
    font-size: calc(13rem/16);
    letter-spacing: 0;
    line-height: 0;
}