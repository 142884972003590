.product-detail__title {
    line-height: calc(38/30);
    letter-spacing: 1px;
    margin-bottom: calc(32rem/16);
    margin-top: calc(5rem/16);
}
.product-detail__price {
    font-size: calc(23rem/16);
    line-height:1;
    letter-spacing: calc(0.5rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(5rem/16);
}
.product-detail__info-icon {
    height: calc(56rem/16);
    padding: calc(4rem/16);
}