.download-teaser {
    box-shadow: 0 calc(15rem/16) calc(30rem/16) 0 rgba(31,31,31,0.05);
    background-color:#fff;
    position: relative;
    height: 100%;
}
.download-teaser--small {
    box-shadow: 0 calc(8rem/16) calc(16rem/16) 0 rgba(31,31,31,0.05);
}
.download-teaser__media {
    height: calc(277rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
}
.download-teaser__body {
    padding: calc(35rem/16) calc(30rem/16) calc(25rem/16) calc(0rem/16);
    display: flex;
    flex-direction: column;
    height: 100%;
    @media screen and (max-width: 1199px) {
        padding: calc(20rem/16);
    }
}
.download-teaser__body--product {
    padding: calc(30rem/16);
}
.download-teaser:after {
    content:'';
    position: absolute;
    width:100%;
    height:100%;
    box-shadow: 0 15px 30px 0 rgba(87,35,129,0.05);
    z-index: -1;
    transition: opacity 0.3s ease;
    left:0;
    right:0;
    bottom:0;
}