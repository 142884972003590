.scroll-top__text {
    font-size: calc(16rem/16);
    letter-spacing: calc(1.14rem/16);
    line-height: 1;
    margin-right: calc(25rem/16);
}
.scroll-top__btn {
    height: calc(50rem/16);
    width: calc(50rem/16);
    display:inline-flex;
    justify-content:center;
    align-items:center;
}
.scroll-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: calc(50rem/16);
    margin-bottom: calc(20rem/16);
}