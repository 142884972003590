.media-gallery__img-landscape__embed {
    padding-top: calc(327 / 495 * 100%);
}
.media-gallery__img-portrait__embed {
    padding-top: calc(491 / 367 * 100%);
}
.media-gallery__title-block {
    padding: calc(50rem/16) calc(40rem/16)  calc(50rem/16) calc(120rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) 0;
    }
}
.media-gallery__video-btn {
    position: absolute;
    color:#fff;
    top:0;
    left:0;
    font-size: calc(60rem/16);
    right:0;
    width:100%;
    height:100%;
    display:block;
    margin: 0 auto;
    color: rgba(255,255,255,0.9);
    background:transparent;
    border:none;
    pointer-events: none;
    z-index: 1;
    transition: transform 0.3s ease;
    line-height:1;
}
.media-gallery__medium:hover .media-gallery__video-btn {
    transform: scale(1.04);
}
.media-gallery__medium:before {
    content:'';
    display: block;
    position: absolute;
    width:100%;
    height:100%;
    background-color: var(--color-primary);
    opacity:0;
    z-index: 1;
    top:0;
    left:0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}
.media-gallery__medium:hover:before {
    opacity: 0.15;
}