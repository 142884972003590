.title--centered-with-line {
    display: grid;
    width: 100%;
    align-items: center;
    text-align: center;
    font-size: calc(16rem/16);
    letter-spacing: 1px;
    grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
    grid-gap: calc(26rem/16);
}
.title--centered-with-line .icon {
    font-size: calc(17rem/16);
}
.title--centered-with-line:before,
.title--centered-with-line:after {
    content: '';
    border-top: 2px solid var(--color-light-grey);
}