.title-block__title--main {
    text-transform: uppercase;
}
.title-block__title:not(.title-block__title--main):after, .title--has-underline:after {
    content:'';
    background-color: var(--color-primary);
    height:calc(1rem/16);
    width: calc(74rem/16);
    display:block;
    margin-top: calc(15rem/16);
    margin-bottom: calc(15rem/16);
}
@media screen and (max-width: 767px) {
    .title--has-underline {
        text-align:center;
    }
    .title--has-underline:after {
        margin: calc(15rem/16) auto;
    }
}
.text-center .title-block__title:not(.title-block__title--main):after {
    margin: calc(15rem/16) auto;
}
.title-block__link {
    margin-top: calc(50rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(20rem/16);
    }
}
.title-block__content-wrapper {
    position:relative;
    margin-top: calc(70rem/16);
    min-height: calc(190rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(20rem/16);
        padding-left: calc(50rem/16);
    }
}
