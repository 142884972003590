.number-spinner__input {
    text-align: center;
}
.btn-number-spinner {
    padding: calc(8rem/16);
    font-size: calc(20rem/16);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}