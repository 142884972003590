.portal-hero-teaser {
    height:100%;
    background-color:#fff;
    padding: calc(53rem/16) calc(18rem/16);
    display:flex;
    flex-direction: column;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) calc(10rem/16);
    }
}
.portal-hero-teaser__title {
    hyphens: auto;
    font-size: calc(20rem/16);
    letter-spacing: calc(2rem/16);
    text-align:center;
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        letter-spacing: 0;
    }
}
.portal-hero-teaser__icon {
    display:block;
    color: var(--color-primary);
    font-size: calc(70rem/16);
    text-align:center;
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(30rem/16);
    }
}
.portal-hero-teaser__content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-align:center;
    margin-top: calc(25rem/16);
    margin-bottom: calc(25rem/16);
    font-size: calc(14rem/16);
    @media screen and (max-width: 767px) {
        display:none;
    }
}
.portal-hero__teaser-row {
    margin-top: calc(-256rem/16);
    @media screen and (max-height: 700px) and (min-width: 768px) {
        margin-top: calc(-130rem/16);
    }
    @media screen and (max-width: 1600px) and (min-width: 768px) {
        margin-top: calc(-225rem/16);
    }
    @media screen and (max-width: 767px) {
        margin-top:0;
    }
}
.portal-hero-teaser__content {
    @media (hover: hover) {
        opacity:0;
        transition: opacity 0.3s ease;
    }
}
.portal-hero-teaser:hover .portal-hero-teaser__content{
    opacity:1;
}