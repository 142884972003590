.form-errors {
    color: var(--color-danger);
    font-size: calc(11rem/16);
    position: absolute;
    right:0;
}
.form-group.has-error .form-control {
    border-bottom: 1px solid var(--color-danger);
}
.form-errors:empty {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.custom-checkbox ul.parsley-errors-list, .custom-radio ul.parsley-errors-list  {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: calc(11rem/16);
    color: var(--color-danger);
}