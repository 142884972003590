.article-item {
    display: flex;
    justify-content: space-between;
    padding: calc(20rem/16) 0;
    border-top: 1px solid #DEE2E6;
}

.article-item__content {
    display: flex;
    column-gap: calc(30rem/16);
    @media screen and (max-width: 991px) {
        flex-direction: column;
        row-gap: calc(12rem/16);
    }
}

.article-item__img {
    width: calc(95rem/16);
    height: calc(95rem/16);
}

.article-item__order-number {
    color: var(--color-dark-grey);
}

.article-item__links {
    display: flex;
    column-gap: calc(20rem/16);
    margin: calc(10rem/16) 0;
    @media screen and (min-width: 768px) {
        column-gap: calc(40rem/16);
        margin: calc(20rem/16) 0;
    }
}

.article-item__icon-link {
    display: flex;
    align-items: center;
    column-gap: calc(10rem/16);
    color: var(--color-dark-grey);
    border: none;
    padding: 0;
    background: none;
    line-height: 1;
}

.article-item__icon-link .icon {
    font-size: calc(18rem/16);
}

.article-item__wishlist-link {
    position: relative;
    &:before {
        content: var(--icon-compare-add);
        font-family: iconfont;
        font-size: calc(18rem/16);
    }
}

.article-item__wishlist-link.is-active {
    &:before {
        content: var(--icon-compare-remove);
    }
}

.article-item__info-text {
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    display: flex;
    align-items: center;
    line-height: 1;
    column-gap: calc(10rem/16);
}

.article-item__info-text:hover {
    cursor: pointer;
}

.article-item__info-text .icon {
    font-size: calc(24rem/16);
}

.article-item__amount {
    margin-top: calc(8rem/16);
}

.article-item__amount-spinner .input-group {
    align-items: center;
    @media screen and (min-width: 768px) {
        column-gap: calc(10rem/16);
    }
}

.article-item__amount-input {
    border-bottom: 0;
    background-color: var(--color-light-grey);
    height: calc(24rem/16);
    @media screen and (min-width: 768px) {
        min-width: calc(40rem/16);
    }
}