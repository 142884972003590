:root {
    --nav-height-mobile: calc(60rem/16);
}
@media screen and (max-width: 767px) {
    .main-nav__search-btn {
        color: var(--color-text-default);
    }

    .main-nav__toggler-bar {
        background-color: var(--color-text-default);
    }

    .main-nav__toggler-text {
        text-transform: none;
    }

    .main-nav__search-btn-text {
        text-transform: none;
    }

    .main-nav__right-icon {
        display: flex;
        flex-direction: column;
        padding: calc(10rem/16);
    }
    
    .main-nav__right-count {
        bottom: calc(8rem/16);
    }

    .main-nav__right-dropdown-menu {
        top: 100%;
        right: 0;
        left: 0;
        min-width: calc(320rem/16);
        margin-top: 0;
    }

    .main-nav__right-dropdown {
        position: static;
    }
}