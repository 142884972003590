.product-info {
    border: calc(1rem / 16) solid #EBEBEB;
}

.product-info--has-box {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
}

.product-info__tabbing {
    padding: calc(16rem / 16) calc(24rem / 16);
    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) calc(50rem / 16);
    }
}

.product-info--has-box .product-info__tabbing {
    @media screen and (min-width: 768px) {
        width: 61%;
    }
}
.product-info__box {
    min-height: 16rem;
    @media screen and (min-width: 768px) {
        width: 39%;
    }
}

.product-info__nav {
    border: none;
    border-bottom: 1px solid var(--color-primary);

    @media screen and (max-width: 767px) {
        border-bottom: none;
        display: flex;
        justify-content: center;
        gap: 5px;
    }
}

.product-info__nav .nav-link {
    text-transform: uppercase;
    font-size: calc(13rem / 16);
    padding: calc(10rem/16) calc(20rem/16);
    font-family: var(--font-default-bold);

    @media screen and (max-width: 767px) {
        border: 1px solid var(--color-primary);
    }
}

.product-info__nav .nav-link {
    border: none;
    transition: color .2s ease-in;
}
.product-info__nav .nav-link:hover {
    color: var(--color-primary);
}
.product-info__nav .nav-link.active {
    border-bottom: calc(1rem / 16) solid var(--color-primary);
    background: var(--color-primary);
    color: #fff;
}

.product-info__box {
    background-color: #F4F5F5;
    padding: calc(16rem / 16) calc(24rem / 16);
    position: relative;
    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) calc(80rem / 16);

    }
}
.product-info__box:after {
    content: var(--icon-package);
    font-family: iconfont;
    position: absolute;
    font-size: calc(200rem / 16);
    bottom: calc(30rem / 16);
    right: calc(50rem / 16);
    line-height: 1;
    opacity: 0.1;
}