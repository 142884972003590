.product-description__gallery__open {
    position: relative;
}

.product-description__gallery__btn {
    position: absolute;
    inset: 0;
    background-color: rgba(244, 245, 245, 0.9);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);
    pointer-events: none;
}

.product-description__gallery__btn:hover {
    color: var(--color-primary);
}

.product-description__gallery__item {
    cursor: pointer;
}

.product-description__gallery__item img {
    overflow: hidden;
    transition: transform 0.5s ease;
}

.product-description__gallery__item:hover img {
    transform: scale(1.05);
}

.product-detail__icon {
    font-size: calc(40rem / 16);
}

.product-detail__btn + .product-detail__btn {
    margin-left: calc(5rem / 16);
}

.product-detail__modal__icon {
    font-size: calc(50rem / 16);
    margin-bottom: calc(20rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        position: absolute;
        font-size: calc(80rem / 16);
        right: calc(128rem / 16);
        top: calc(66rem / 16);
    }
}

.product-detail__modal__content {
    position: relative;
    padding: calc(20rem / 16);
    @media screen and (min-width: 768px) {
        padding: calc(95rem / 16) calc(128rem / 16);
    }
}

.product-detail__modal__image img{
    width: 100%;
    height: 100%;
}