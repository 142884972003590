.meta-navbar {
    justify-content: center;
    padding:0;
    @media screen and (max-width: 767px) {
        display:flex;
        flex-direction:column;
        margin-top: auto;
    }
}
.meta-navbar__nav {
    @media screen and (min-width: 768px) {
        padding-left: calc(20rem/16);
    }
    @media screen and (max-width: 767px) {
        display:block;
    }
}
.meta-navbar__nav-item {
    display: inline-flex;
    font-size: calc(13rem/16);
    letter-spacing: calc(0.3rem/16);
    line-height: calc(16/13);
    padding: calc(10rem/16) calc(10rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) calc(20rem/16) calc(10rem/16) 0;
    }
}