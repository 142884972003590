.product-thumb-slider__embed {
    padding-top: calc(802 / 507 * 100%);
}
.product-thumb-slider {
    overflow: hidden;
}
.product-thumb-slider__thumbs:not(.slick-slider)>div+div,
.product-thumb-slider__main:not(.slick-slider)>div+div{
    display: none;
}
.product-thumb-slider__media {
    overflow: hidden;
    position: relative;
}
.product-thumb-slider__media-img {
    max-height: 75vh;
    opacity:0;
    height: auto;
    max-width: 100%;
    position: relative;
    width: auto;
    @media screen and (max-width: 767px) {
        max-width: 100%;
        height: auto;
    }
}
.product-thumb-slider .slick-current.slick-active .product-thumb-slider__media-img {
    opacity:1;
}
.product-thumb-slider__thumbs .product-thumb-slider__embed {
    opacity:0.5
}
.product-thumb-slider__thumbs {
    position:relative;
    z-index: 3;
}
.product-thumb-slider__thumbs .slick-active.slick-current .product-thumb-slider__embed {
    border: 1px solid var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
    opacity:1;
}

.zoomContainer {
    overflow: hidden;
}