.form-group {
    position: relative;
    margin-bottom: 2rem;
}
.floating-label {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
}

.floating-label.label--has-icon-left {
    left: 2rem;
}

textarea.form-control{
    position: relative;
    top: 0;
    transform: translate(0);
}

textarea ~ .floating-label{
    transform: translate3d(0, 0, 0) scale(1);
    top: .5rem;
}

textarea.form-control.has-value ~ .floating-label,
textarea.form-control:focus ~ .floating-label{
    transform: translateY(-100%) scale(.8);
}
.form-control:focus ~ .floating-label,
select ~ .floating-label,
.form-control.has-value ~ .floating-label,
.form-group.has-value .floating-label {
    transform: translateY(-135%) scale(.8);
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(-135%) scale(.8);
}


textarea.form-control.has-value ~ .floating-label.label--has-icon-left,
textarea.form-control:focus ~ .floating-label.label--has-icon-left{
    transform: translateY(-145%) translateX(-2rem) scale(.8);
}
.form-control:focus ~ .floating-label.label--has-icon-left,
select ~ .floating-label.label--has-icon-left,
.form-control.has-value ~ .floating-label.label--has-icon-left,
.form-group.has-value .floating-label.label--has-icon-left {
    transform: translateY(-145%) translateX(-2rem) scale(.8);
}

.form-control:-webkit-autofill ~ .form-control-label.label--has-icon-left,
.form-control:-webkit-autofill:focus ~ .form-control-label.label--has-icon-left {
    transform: translateY(-145%) translateX(-2rem) scale(.8);
}