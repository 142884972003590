.main-footer {
    background-color: var(--color-light-grey);
    padding: calc(36rem/16) 0;
}
.main-footer__item--spacing {
    padding: calc(35rem/16) 0;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) 0;
    }
}
.main-footer__item--spacing-top {
    padding-top: calc(35rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(20rem/16);
    }
}
.main-footer__logo {
    width: calc(220rem/16);
    margin-bottom: calc(40rem/16);
    @media screen and (max-width: 767px) {
        margin: 0 auto;
    }
}
.main-footer__logo-img {
    width: calc(220rem/16);
    @media screen and (max-width: 1600px) and (min-width: 1200px){
        width: calc(180rem/16);
    }
    @media screen and (max-width: 1199px) and (min-width: 768px){
        width: calc(140rem/16);
    }
}
.main-footer__title {
    position: absolute;
    transform: rotate(-90deg) translateX(-100%) translateY(-100%);
    transform-origin: left top;
    top: 0;
    padding: 0 0 1.5rem;
    margin:0;
    height:calc(52rem/16);
    width: calc(155rem/16);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    white-space: nowrap;
}
.main-footer__title--big {
    width: calc(220rem/16);
}
.main-footer__title:after {
    content: '';
    height: calc(1rem/16);
    width: 100%;
    background: var(--color-text-default);
    bottom: calc(34rem/16);
    right: 0;
    line-height: 1;
    margin-bottom: calc(10rem/16);
    margin-left: calc(8rem/16);
}
.main-footer__title-wrapper {
    position:relative;
    padding-left: calc(52rem/16);
    min-height: calc(155rem/16);

    @media (min-width: 991px) and (max-width: 1450px) {
        margin-left: -2rem;
    }
}
.main-footer__contact-list {
    margin-top: calc(11rem/16);
}
.main-footer__contact-list-title {
    color: var(--color-dark-grey);
    font-size: calc(12rem/16);
    letter-spacing: calc(0.26rem/16);
}
.main-footer__contact-list a {
    color: var(--color-primary);
}
.main-footer__social-button {
    background-color: var(--color-primary);
    color:#fff;
    font-size: calc(24rem/16);
    height: calc(40rem/16);
    width: calc(40rem/16);
    display:flex;
    align-items:center;
    justify-content: center;
    margin-bottom: calc(5rem/16);
    margin-right:0;
    transition: background-color 0.3s ease;
}
.main-footer__social-button:hover {
    background-color: var(--color-primary-dark);
    color:#fff;
}
.main-footer__social-list {
    @media screen and (max-width: 767px) {
        display: flex;
        justify-content: center;
    }
}
.main-footer__social-list-item {
    @media screen and (max-width: 767px) {
        display: inline-flex;
        margin-left: calc(5rem/16);
        margin-right: calc(5rem/16);
    }
}

.main-footer__advantages-list {
    list-style-type: none;
    padding-left: 0;
}

.main-footer__advantage-item {
    display: flex;
    gap: .75rem;
    margin-bottom: 0.25rem;
}

.main-footer__advantages-read-more {
    color: var(--color-primary);
    display: flex;
    gap: 0.75rem;
    flex-wrap: nowrap;
    text-transform: uppercase;
    margin-top: 2rem;
    transition: color 0.3s ease;
    align-items: center;
    font-size: var(--font-size-default);

    .icon {
        transition: transform 0.3s ease;
        margin-bottom: 0.35rem;
    }

    &:hover {
        color: var(--color-primary-dark);

        .icon {
            transform: translateX(.5rem);
        }
    }
}