html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    letter-spacing: calc(0.3rem/16);
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.main-content__body {
    margin-top: calc(60rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(35rem/16);
    }
}
.item-shadow {
    position:relative;
}
.item-shadow:after {
    box-shadow: 0 calc(2rem/16) calc(50rem/16) 0 rgba(31,31,31,0.25);
    content:'';
    position:absolute;
    z-index: -1;
    bottom:calc(0rem/16);
    width: 80%;
    margin:0 auto;
    height: calc(50rem/16);
    left:0;
    right:0;
}
.main-wrapper {

}
body {
    overflow-x: hidden;
}