.wysiwyg-with-medium__embed {
    padding-top: calc(495 / 743 * 100%);
}
.wysiwyg-with-media__content {
    padding: calc(20rem/16);
    max-width: calc(600rem/16);
    width: 100%;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) 0;
    }
}
.wysiwyg-with-medium__media {
    overflow: hidden;
}
.wysiwyg-with-medium__embed {
    position:relative;
    cursor:pointer;
}
.wysiwyg-with-medium__embed:before {
    content:'';
    display: block;
    position: absolute;
    width:100%;
    height:100%;
    background-color: var(--color-primary);
    opacity:0;
    z-index: 1;
    top:0;
    left:0;
    pointer-events: none;
    transition: opacity 0.3s ease;
}
.wysiwyg-with-medium__embed:hover:before {
    opacity: 0.15;
}
.wysiwyg-with-medium__media-link {
    position:relative;
    background-color: #FFF;
}
.wysiwyg-with-medium__media-link:not(.no-shadow):after {
    box-shadow: 0 2px 50px 0 rgba(31,31,31,0.25);
    content:'';
    position:absolute;
    z-index: -1;
    bottom:calc(0rem/16);
    width: 80%;
    margin:0 auto;
    height: calc(50rem/16);
    left:0;
    right:0;
}

.wysiwyg-with-medium--shadow {
    box-shadow: 0 0.9375rem 1.875rem -0.875rem rgba(87,35,129,.05);
    overflow-x:hidden;
}
.wysiwyg-with-medium__media-caption {
    margin-top: calc(20rem/16);
    font-size: calc(14rem/16);
    position:absolute;
    @media screen and (max-width: 767px) {
        position:Relative;
    }
}
.wysiwyg-with-medium__video-btn {
    position: absolute;
    color:#fff;
    top:0;
    left:0;
    font-size: calc(60rem/16);
    right:0;
    width:100%;
    height:100%;
    display:block;
    margin: 0 auto;
    color: rgba(255,255,255,0.9);
    background:transparent;
    border:none;
    pointer-events: none;
    z-index: 1;
    transition: transform 0.3s ease;
}
.wysiwyg-with-medium__media:hover .wysiwyg-with-medium__video-btn {
    transform: scale(1.04);
}
.wysiwyg-with-medium__media-caption--right {
    display:flex;
    justify-content:flex-end;
    padding-right: calc(30rem/16);
    padding-left: calc(10rem/16);
    width: 100%;
    @media screen and (max-width: 767px) {
        padding:0;
        justify-content:flex-start;
    }
}