.wysiwyg { /* moved from libs.css */
    width: auto;
    min-width: 100%;
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
    text-decoration: underline;
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}
.wysiwyg .strong, .wysiwyg b {
    font-family: var(--font-default-bold);
}
.wysiwyg ul {
    padding-left: 0;
    list-style: none;
}
.wysiwyg ul > li {
    padding-left: 2rem;
    text-indent: calc(-9rem/16);
    letter-spacing: calc(0.3rem/16);
    margin-bottom: calc(15rem/16);
}
.wysiwyg ul > li:before {
    content: '';
    background-color: var(--color-highlight);
    width: calc(6rem/16);
    height: calc(6rem/16);
    font-size: calc(30rem/16);
    display: inline-block;
    margin-right: .5rem;
    line-height: calc(23rem/16);
    vertical-align:middle;
    margin-bottom: 2px;

}