.stretch-link {
    position: relative;
}
.stretch-link a:not(.stretch-link__link),
.stretch-link button:not(.stretch-link__link),
.stretch-link label:not(.stretch-link__link),
.stretch-link input:not(.stretch-link__link),
.stretch-link select:not(.stretch-link__link),
.stretch-link textarea:not(.stretch-link__link),
.stretch-link__interactive {
    position: relative;
    z-index: 12;
}
.stretch-link__link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
}
.stretch-link__link::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
}

@media (min-width: 768px){
    .stretch-link-md {
        position: relative;
    }
    .stretch-link-md a:not(.stretch-link-md__link),
    .stretch-link-md button:not(.stretch-link-md__link),
    .stretch-link-md label:not(.stretch-link-md__link),
    .stretch-link-md input:not(.stretch-link-md__link),
    .stretch-link-md select:not(.stretch-link-md__link),
    .stretch-link-md textarea:not(.stretch-link-md__link),
    .stretch-link-md__interactive {
        position: relative;
        z-index: 12;
    }
    .stretch-link-md__link::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        pointer-events: auto;
        content: "";
        background-color: rgba(0,0,0,0);
    }
    .stretch-link-md__link::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
        pointer-events: auto;
        content: "";
        background-color: rgba(0,0,0,0);
    }
}