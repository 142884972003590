.twitter-typeahead {
    width: 100%;
    display: block !important;
}
.tt-menu {
    padding: calc(5rem / 16) calc(30rem / 16);
    right: 0;
    background: #ffffff;
    margin-bottom: 10px;
    max-height: 450px;
    overflow-y: auto;
    color: var(--color-text-default);
    text-align: left;
    z-index: 999 !important;
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
@media screen and (max-width: 767px) {
    .tt-menu {
        padding: calc(5rem / 16);
        width: calc(100% + 40rem/16);
    }
    .tt-menu:not(.tt-empty) {
        display:block !important;
    }
}
.tt-suggestion.tt-selectable,
.tt-dataset-heading,
.tt-empty-message {
    padding: 6px 0;
}
.tt-suggestion.tt-selectable:hover {
    cursor: pointer;
    background-color: var(--color-light-grey);
    border:none;
    border-bottom: 1px solid var(--color-primary);
}
.tt-cursor .card, .tt-cursor {
    border: 1px solid var(--color-primary);
    margin: -1px;
}
.tt-hint {
    color: #999
}

.tt-dataset-heading {
    position: relative;
    padding: 5px 0 5px 25px;
    margin-bottom: 5px;
    border-bottom: 1px solid var(--color-border-grey);
}
.tt-dataset-heading-icon {
    position: absolute;
    top: 45%;
    left: 0;
    transform: translateY(-50%);
}
.tt-menu .mark, .tt-menu mark {
    background-color:transparent;
    font-family: var(--font-default-bold);
    padding:0;
}
.tt-dataset-heading-link {
    color: var(--color-secondary);
    float:right;
}
.typeahead-heading__icon {
    font-size: calc(20rem/16);
    margin-right: calc(10rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem / 16);
    }
}
.typeahead-heading__text {
    font-size: calc(16rem / 16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem / 16);
    }
}
.typeahead-heading {
    padding-top: calc(10rem/16);
    padding-bottom: calc(10rem/16);
    border-bottom: 1px solid var(--color-border-grey);
}
.typeahead-heading__link {
    color: var(--color-primary);
}
.tt-dataset + .tt-dataset {
    margin-top: calc(20rem/16);
}
.tt-dataset {
    padding-left: calc(20rem/16);
    @media screen and (max-width: 767px) {
        padding-left: calc(10rem/16);
    }
}
.tt-dataset:empty {
    display: none;
}
.tt-menu--top {
    top: unset!important;
    bottom: 120% !important;
}