.form-group__icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: calc(18rem/16);
}

.form-control--has-icon {
    padding-right: calc(24rem/16);
    text-overflow: ellipsis;
}

.form-control--has-icon.form-control--has-icon-left {
    padding-left: calc(32rem/16);
    padding-right: 0;
}

.form-group__icon.form-group__icon--left {
    font-size: 1.5rem;
    left: 0;
    right: unset;
}