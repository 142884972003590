.comparison-table__products {
    display: grid;
    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        column-gap: calc(25rem/16);
        transition: transform 300ms ease;
        overflow-x: auto;
    }
    @media screen and (min-width: 768px) {
        display: flex;
        column-gap: calc(30rem/16);
        justify-content: space-between;
        padding-bottom: calc(30rem/16);
        border-bottom: 1px solid var(--color-border-grey);
    }
}

.comparison-table__products.scroll-left {
    @media screen and (max-width: 767px) {
        transform: translateX(49vw);
    }
}

.comparison-table__products.scroll-right {
    @media screen and (max-width: 767px) {
        transform: translateX(-49vw);
    }
}

.comparison-table__legend {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: calc(250 / 1920 * 100vw);
}

.comparison-table__legend-title {
    font-size: calc(16rem/16);
    line-height: calc(25rem/16);
    letter-spacing: 0;
    color: var(--color-dark-grey);
}

.comparison-table__table {
    border: none;
    margin-top: calc(30rem/16);
    table-layout: fixed;
    @media screen and (max-width: 991px) {
        margin: 0 calc(-15rem/16);
        width: 100vw;
    }
}

.comparison-table__table th {
    @media screen and (max-width: 991px) {
        position: absolute;
        top: calc(-20rem/16);
        left: calc(15rem/16);
    }
    @media screen and (min-width: 992px) {
        width: calc(250 / 1920 * 100vw);
    }
}

.comparison-table__table th, .comparison-table__table td {
    padding: calc(33rem/16) 0 calc(10rem/16);
    @media screen and (min-width: 992px) {
        padding: calc(16rem/16) calc(30rem/16);
    }
}

.comparison-table__table tr {
    display: flex;
    column-gap: calc(30rem/16);
    @media screen and (max-width: 991px) {
        position: relative;
        padding: 0 calc(15rem/16);
    }
}

.comparison-table__table--difference {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: calc(-1rem/16);
        top: 50%;
        transform: translateY(-50%);
        width: calc(2rem/16);
        height: calc(24rem/16);
        background-color: var(--color-primary);
    }
}

.comparison-table__mobile-nav {
    width: calc(135rem/16);
    height: calc(60rem/16);
    box-shadow: calc(0rem/16) calc(0rem/16) calc(16rem/16) rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: calc(35rem/16);
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 0 calc(26rem/16);
    display: flex;
    align-items: center;
    z-index: 1;
    @media screen and (min-width: 768px) {
        display: none;
    }
}

.comparison-table__mobile-nav-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    color: var(--color-border-grey);
}

.comparison-table__mobile-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: calc(5rem/16);
    position: absolute;
    bottom: calc(5rem/16);
    left: 50%;
    transform: translateX(-50%);
}

.comparison-table__mobile-indicator-dot {
    width: calc(5rem/16);
    height: calc(5rem/16);
    border-radius: 50%;
    border: 1px solid var(--color-border-grey);
    &.is-selected {
        background-color: var(--color-border-grey);
    }
    &.is-active {
        border: 1px solid var(--color-default);
    }
    &.is-active.is-selected {
        background-color: var(--color-default);
    }
}