:root {
    --portal-hero-top-spacing: calc(70rem/16);
}
.portal-hero {
    position:relative;
    z-index: 0;
}
.portal-hero__teaser-row {
    z-index: 1;
}
.typeahead--open .portal-hero__teaser-row {
    z-index: 0;
}
.typeahead--open .portal-hero {
    z-index: unset;
}
.portal-hero__embed:before {
    padding-top:  calc(100vh - var(--nav-height));
    @media screen and (max-width: 767px) and (orientation: landscape) {
        padding-top: 150vh;
    }
    @media screen and (max-width: 767px) and (orientation: portrait) {
        padding-top: 80vh;
    }
}
.portal-hero__embed:after {
    background: linear-gradient(180deg, #000000 0%, rgba(0,0,0,0.43) 100%);
    opacity:0.6;
    position: absolute;
    content:'';
    display:block;
    width:100%;
    height:100%;
    top:0;
    left:0;
}
.portal-hero__content {
    position: absolute;
    top:0;
    width:100%;
    height:100%;
    z-index: 1;
    color:#fff;
    text-align:center;
    padding-top: var(--portal-hero-top-spacing);
    @media screen and (max-width: 1800px) {
        padding-top: calc(40rem/16);
    }
}

.portal-hero__subtitle {
    font-size: calc(20rem/16);
    /* 24px @ 992px increasing to 30px @ 1920px */
    @media (min-width: 992px) {
        font-size: calc(1.5rem + ((1vw - 0.62rem) * 0.6466));
        /* Where: * 0.6466 = 100 * font_Size_Difference / viewport_Width_Difference */
    }
    /* Prevent font scaling beyond this breakpoint */
    @media (min-width: 1920px) {
        font-size: calc(30rem / 16);
    }
}
.portal-hero__subtitle:after {
    content:'';
    background-color: #fff;
    height:calc(2rem/16);
    width: calc(74rem/16);
    display:block;
    margin: calc(26rem/16) auto;
    @media screen and (max-width: 767px) {
        margin: calc(15rem/16) auto;
    }
}
.portal-hero__title {
    line-height: calc(80/70);
    letter-spacing: calc(3rem/16);
    text-transform:uppercase;
    margin-bottom: calc(67rem/16);
    font-size: calc(30rem/16);
    @media screen and (max-width: 1800px) {
        margin-bottom: calc(30rem/16);
    }
    @media (min-width: 992px) {
        font-size: calc(2.625rem + ((1vw - 0.62rem) * 1.9397));
        /* Where: * 1.9397 = 100 * font_Size_Difference / viewport_Width_Difference */
    }
    /* Prevent font scaling beyond this breakpoint */
    @media (min-width: 1920px) {
        font-size: calc(60rem / 16);
    }
}
.portal-hero__search-input {
    height: calc(60rem/16);
    font-size: calc(20rem/16);
    border:none;
    padding: calc(17rem/16) calc(30rem/16);
    @media screen and (max-width: 767px) {
        height: calc(40rem/16);
        font-size: calc(14rem/16);
    }
}
.portal-hero__search-btn {
    height: calc(60rem/16);
    width: calc(60rem/16);
    font-size: calc(23rem/16);
    display:flex;
    justify-content:center;
    align-items:center;
    line-height:1;
    @media screen and (max-width: 767px) {
        height: calc(40rem/16);
        width: calc(40rem/16);
        font-size: calc(18rem/16);
        padding:0;
    }
}
.portal-hero-slider__arrow-container {
    position:absolute;
    bottom:0;
    padding-bottom: calc(4rem/16);
    right:0;
    z-index: 999;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding-bottom: calc(130rem/16);
        left:0;
    }
}
.portal-hero__slider .slick-slide > div {
    display: flex;
    align-content: stretch;
    flex-direction: row;
    width: 100%;
}
.portal-hero__search-form {
    display:block;
}
/* scroll info */
.portal-hero__scroll {
    color: #fff;
    position: absolute;
    bottom: 0;
    width: 10%;
    height: calc(140rem/16);
    text-align: right;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 999;
    @media screen and (max-width: 1199px) {
        display:none;
    }
}
.portal-hero__scroll-text {
    transform: rotate(-90deg);
    position: relative;
    transform-origin:top;
    height: auto;
    width: calc(100rem/16);
    border-bottom: 1px solid #fff;
    right: 0;
    display: block;
    padding: calc(10rem/16);
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    font-size: calc(18rem/16);
    letter-spacing: 1.2px;
}
.portal-hero__scroll-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: calc(26rem/16);
    transform: translateX(50%);
}
.portal-hero__scroll:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1px;
    background: var(--color-primary);
    display: block;
    height: calc(64rem/16);
    bottom: 0;
    top: 100%;
}
.portal-hero__image {
    background-size:cover;
    background-position: center;

}
.portal-hero__slider .slick-list, .portal-hero__slider .slick-track {transform: none !important;}
.portal-hero__slider.slick-initialized .slick-slide {z-index: auto !important;}
.portal-hero__content {
    z-index: 999
}
.portal-hero-slider__arrow-container .slider__arrow {
    @media screen and (max-width: 1199px) {
        height: 40px;
        width: 40px;
    }
}
.portal-hero__claim {
    background-color: var(--color-primary-75);
    text-align: center;
    position:relative;
    padding: calc(40rem/16);
    margin: 0 calc(15rem/16) calc(30rem/16);
    @media screen and (min-width: 768px) {
        max-width: calc(480rem/16);
        margin: clamp(calc(8rem/16), 4vw, calc(20rem/16)) auto clamp(calc(24rem/16), 4vw, calc(70rem/16));
        padding: clamp(calc(16rem/16), 4vw, calc(60rem/16));
    }
}
@media screen and (min-width: 768px) {
    .portal-hero__claim--left {
        margin: clamp(calc(8rem/16), 4vw, calc(20rem/16)) auto clamp(calc(24rem/16), 4vw, calc(70rem/16)) 0;
    }
    .portal-hero__claim--right {
        margin: clamp(calc(8rem/16), 4vw, calc(20rem/16)) 0 clamp(calc(24rem/16), 4vw, calc(70rem/16)) auto;
    }
}

.portal-hero__claim__arrow-top,
.portal-hero__claim__arrow-bottom {
    position: absolute;
    width: calc(50rem/16);
    @media screen and (min-width:768px){
        width: clamp(calc(24rem/16), 4vw, calc(80rem/16));
    }
}

.portal-hero__claim__arrow-top {
    left: 0;
    top: 0;
}
.portal-hero__claim__arrow-bottom {
    right: 0;
    bottom: 0;
}
.portal-hero__claim__subtitle {
    letter-spacing: calc(1rem/16);
    line-height: 1.1;
    text-align: left;
    font-size: clamp(calc(16rem/16), 4.5vw, calc(20rem/16));
    @media screen and (min-width:768px){
        font-size: clamp(calc(18rem/16), 2vw, calc(28rem/16));
    }
}
.portal-hero__claim__title {
    letter-spacing: calc(1rem/16);
    line-height: 1.1;
    text-align: left;
    position: relative;
    font-family: var(--font-default-bold);
    //font-size: calc(20rem/16);
    font-size: clamp(calc(16rem/16), 4.5vw, calc(20rem/16));
    margin-top: calc(24rem/16);
    @media screen and (min-width:768px){
        font-size: clamp(calc(18rem/16), 4.5vw, calc(24rem/16));
        margin-top: calc(40rem/16);
    }
}
.portal-hero__claim__title:before {
    content: "";
    position: absolute;
    top:calc(-13rem/16);
    left: 0;
    width: calc(75rem/16);
    height: calc(2rem/16);
    background: #fff;
    @media screen and (min-width:768px){
        top: calc(-21rem/16);
    }
}

.portal-hero__slider-item{
    position: relative;
}