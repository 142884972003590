.product-info-accordion__body {
    padding: calc(10rem/16) 0 calc(30rem/16);
}
.product-info-accordion__title {
    font-family: var(--font-default-bold);
}
.product-info-accordion__content {
    overflow: hidden;
}
.product-info-accordion__title-img {
    padding-left: calc(5rem/16);
    margin-bottom: calc(10rem/16);
}
.product-info-accordion__media {
    @media screen and (max-width: 767px) {
        margin-top: calc(20rem/16);
        text-align:center;
    }
}

.product-info-accordion__link {
    text-decoration: none !important;
    line-height: 1;
}

.product-info-accordion__link .icon {
    font-size: calc(24rem/16);
}