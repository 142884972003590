.cart-sidebar__card {
    padding: calc(35rem/16) calc(40rem/16);
    box-shadow: 0 calc(15rem/16) calc(40rem/16) rgba(0, 0, 0, 0.1);
    margin-bottom: calc(30rem/16);
}

.cart-sidebar__info-text {
    padding: calc(20rem/16) calc(30rem/16);
    font-size: calc(14rem/16);
    line-height: calc(23rem/14);
    border: 1px solid var(--color-primary);
    position: relative;
    margin-bottom: calc(20rem/16);
    letter-spacing: 0;
}

.cart-sidebar__info-icon {
    font-size: calc(24rem/16);
    color: var(--color-primary);
    position: absolute;
    top: calc(-16rem/16);
    left: calc(-16rem/16);
    padding: calc(8rem/16);
    background-color: white;
}

.cart-sidebar__customer-text {
    color: var(--color-purple);
    display: flex;
    align-items: center;
    column-gap: calc(10rem/16);
    justify-content: center;
    font-size: calc(12rem/16);
    letter-spacing: 0;
    line-height: 1;
    margin-bottom: calc(30rem/16);
}

.cart-sidebar__customer-text .icon {
    font-size: calc(24rem/16);
}

.cart-sidebar__awards-logos {
    display: flex;
    justify-content: center;
    column-gap: calc(30rem/16);
}

.cart-sidebar__logo {
    width: calc(65rem/16);
}