.back-link{
    color: var(--color-primary);
    display: flex;
    align-items: center;
}
.back-link__icon,
.back-link__text {
    color: var(--color-primary);
}

.back-link__icon {
    margin-right: calc(15rem / 16);
}