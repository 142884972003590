.header-checkout {

}

.header-checkout__nav {
    padding: 0 calc(10rem/16);
    height: calc(60rem/16);
    box-shadow: 0 calc(15rem/16) calc(40rem/16) rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    @media screen and (min-width: 768px) {
        padding: 0 calc(40rem/16);
        height: calc(100rem/16);
    }
}

.header-checkout__nav-list {
    display: flex;
    align-items: center;
    grid-column: 2 / 3;
    list-style: none;
    column-gap: calc(34rem/16);
    padding: 0;
    @media screen and (max-width: 767px) {
        padding-left: calc(12rem/16);
    }
    @media screen and (min-width: 768px) {
        column-gap: calc(64rem/16);
    }
}

.header-checkout__brand {
    grid-column: 1 / 2;
}

.header-checkout__brand-img {
    height: calc(27rem/16);
    @media screen and (min-width: 768px) {
        height: calc(40rem/16)
    }
}

.header-checkout__nav-item {
    margin: 0;
    font-size: calc(12rem/16);
    color: var(--color-dark-grey);
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: calc(100%);
        transform: translateY(-50%);
        height: 1px;
        width: calc(26rem/16);
        background-color: var(--color-dark-grey);
        @media screen and (min-width: 768px) {
            width: calc(56rem/16);
        }
    }
}

.header-checkout__nav-item:first-child:before {
    display: none;
}

.header-checkout__nav-item.active {
    color: var(--color-text-default);
    &:before {
        background-color: var(--color-text-default);
    }
}

.header-checkout__nav-item.complete {
    color: var(--color-primary);
    &:before {
        background-color: var(--color-primary);
    }
}

.header-checkout__nav-item .icon {
    font-size: calc(24rem/16);
}

.header-checkout__nav-link {
    display: flex;
    align-items: center;
    line-height: 1;
}

.header-checkout__nav-link-text {
    @media screen and (max-width: 767px) {
        display: none;
    }
}