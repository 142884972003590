.order-summary {
    background-color: var(--color-light-grey);
    padding: calc(80rem/16) calc(0rem/16) calc(60rem/16);
}

.order-summary__content {
    padding: calc(30rem/16) 0;
    border-top: 1px solid var(--color-border-grey);
    border-bottom: 1px solid var(--color-border-grey);
}

.order-summary__content-item + .order-summary__content-item {
    @media screen and (max-width: 767px) {
        padding-top: calc(16rem/16);
    }
}