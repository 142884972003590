.product-item__embed {
    padding-top: calc(290 / 332 * 100%);
}
.product-item {
    padding: calc(30rem/16);
    background-color:#fff;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor:pointer;
    @media screen and (max-width: 1199px){
        padding: calc(10rem/16) 0 ;
    }
}
.product-item__media {
    border-bottom: calc(1rem/16) solid var(--color-grey);
}
.product-item__body {
    padding: calc(18rem/16) 0 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content:space-between;
}
.product-item:before {
    content:'';
    position: absolute;
    width:80%;
    height:calc(100rem/16);
    bottom:calc(0rem/16);
    left:0;
    right:0;
    margin:0 auto;
    z-index: -1;
    box-shadow: 0 calc(20rem/16) calc(20rem/16) calc(-10rem/16) rgba(87,35,129,0.05);
}
.product-item:after {
    content:'';
    position: absolute;
    width:100%;
    height:100%;
    box-shadow: 0 15px 30px 0 rgba(87,35,129,0.05);
    z-index: -1;
    opacity:0;
    transition: opacity 0.3s ease;
    left:0;
    right:0;
    bottom:0;
}
.product-item:hover:after {
    opacity:1;
}
.product-item__order-id {
    color: var(--color-dark-grey);
    font-size: calc(12rem/16);
}
.product-item__title {
    font-size: calc(16rem/16);
    letter-spacing: calc(1rem/16);
    line-height: calc(25/16);
    margin-bottom: calc(30rem/16);
}
.product-item__description{
    font-size: calc(14rem/16);
    margin-top: calc(6rem/16);
}
.product-item__price {
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    line-height: calc(25/16);
}
.product-item__price-info {
    color: var(--color-dark-grey);
    font-size: calc(12rem/16);
}
.product-item__feature {
    background-color:#fff;
    z-index: 2;
    position: relative;
    display: inline-block;
    margin-right: calc(2rem/16);
    margin-bottom: calc(2rem/16);
    padding: calc(4rem/16);
}
.product-item__feature__img {
    height: calc(25rem / 16);
}
.product-item__feature-wrapper {
    position: absolute;
    top:0;
    width: 100%;
    display:flex;
    flex-wrap: wrap;
}

.product-item__compare-link {
    &:before {
        font-family: iconfont;
        content: var(--icon-compare-add);
        font-size: calc(24rem/16);
    }
}

.product-item__compare-link.is-active {
    &:before {
        content: var(--icon-compare-remove);
        color: var(--color-danger);
    }
}

.product-item__compare-link:disabled {
    &:before {
        color: var(--color-grey);
    }
}