.flag {
    display: inline-block;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-image: url(/static/img/flag-sprite/sprite.svg);
}
.flag-flag_eu {
    width: 20px;
    height: 20px;
    background-position: 0 0;
}
.flag-flag_at {
    width: 20px;
    height: 20px;
    background-position: -36px 0;
}
.flag-flag_au {
    width: 20px;
    height: 20px;
    background-position: -72px 0;
}
.flag-flag_be {
    width: 20px;
    height: 20px;
    background-position: -108px 0;
}
.flag-flag_bg {
    width: 20px;
    height: 20px;
    background-position: -144px 0;
}
.flag-flag_ca {
    width: 20px;
    height: 20px;
    background-position: -180px 0;
}
.flag-flag_ch {
    width: 20px;
    height: 20px;
    background-position: -216px 0;
}
.flag-flag_cy {
    width: 20px;
    height: 20px;
    background-position: -252px 0;
}
.flag-flag_cz {
    width: 20px;
    height: 20px;
    background-position: -288px 0;
}
.flag-flag_de {
    width: 20px;
    height: 20px;
    background-position: -324px 0;
}
.flag-flag_dk {
    width: 20px;
    height: 20px;
    background-position: -360px 0;
}
.flag-flag_ee {
    width: 20px;
    height: 20px;
    background-position: -396px 0;
}
.flag-flag_es {
    width: 20px;
    height: 20px;
    background-position: -432px 0;
}
.flag-flag_fi {
    width: 20px;
    height: 20px;
    background-position: -468px 0;
}
.flag-flag_fr {
    width: 20px;
    height: 20px;
    background-position: -504px 0;
}
.flag-flag_gr {
    width: 20px;
    height: 20px;
    background-position: -540px 0;
}
.flag-flag_hr {
    width: 20px;
    height: 20px;
    background-position: 0 -36px;
}
.flag-flag_hu {
    width: 20px;
    height: 20px;
    background-position: -36px -36px;
}
.flag-flag_ie {
    width: 20px;
    height: 20px;
    background-position: -72px -36px;
}
.flag-flag_int {
    width: 20px;
    height: 20px;
    background-position: -108px -36px;
}
.flag-flag_it {
    width: 20px;
    height: 20px;
    background-position: -144px -36px;
}
.flag-flag_lt {
    width: 20px;
    height: 20px;
    background-position: -180px -36px;
}
.flag-flag_lu {
    width: 20px;
    height: 20px;
    background-position: -216px -36px;
}
.flag-flag_lv {
    width: 20px;
    height: 20px;
    background-position: -252px -36px;
}
.flag-flag_mt {
    width: 20px;
    height: 20px;
    background-position: -288px -36px;
}
.flag-flag_nl {
    width: 20px;
    height: 20px;
    background-position: -324px -36px;
}
.flag-flag_no {
    width: 20px;
    height: 20px;
    background-position: -360px -36px;
}
.flag-flag_pl {
    width: 20px;
    height: 20px;
    background-position: -396px -36px;
}
.flag-flag_pt {
    width: 20px;
    height: 20px;
    background-position: -432px -36px;
}
.flag-flag_ro {
    width: 20px;
    height: 20px;
    background-position: -468px -36px;
}
.flag-flag_si {
    width: 20px;
    height: 20px;
    background-position: -504px -36px;
}
.flag-flag_sk {
    width: 20px;
    height: 20px;
    background-position: -540px -36px;
}
.flag-flag_uk {
    width: 20px;
    height: 20px;
    background-position: 0 -72px;
}
.flag-flag_us {
    width: 20px;
    height: 20px;
    background-position: -36px -72px;
}