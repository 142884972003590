/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-light-green { color: var(--color-light-green); }
.text-middle-green { color: var(--color-middle-green); }
.text-green { color: var(--color-green); }
.text-light-purple { color: var(--color-light-purple); }
.text-middle-purple { color: var(--color-middle-purple); }
.text-purple { color: var(--color-purple); }
.text-red { color: var(--color-danger); }
.text-primary { color: var(--color-primary); }
.text-info { color: var(--color-info); }

/* Background Color Helper */
.bg-light-grey { background-color: var(--color-light-grey); }

/* Z-Index */
.zindex--0 {
    z-index: 0;
}

.zindex--1 {
    z-index: 1;
}

/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.vh-70 {height: 70vh;}
@media screen and (min-width: 768px) {
    .vh-md-100 {
        height: 100vh;
    }
}
.clickable {cursor: pointer;}
.fz12 { font-size: calc(12rem/16); }
.fz14 { font-size: calc(14rem/16); }
.fz20 { font-size: calc(20rem/16); }

.info-badge {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    font-size: calc(12rem/16);
    width: calc(20rem/16);
    height: calc(20rem/16);
    display:inline-flex;
    justify-content:center;
    align-items:center;
    border-radius: 50%;
}

.form-control-icon-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.form-control-additional-link {
    position: absolute;
    right: 0;
    left: 0;
}

.lh-1 { line-height: 1; }
.text-transform-none { text-transform: none; }
.mb-11 { margin-bottom : calc(11rem/16); }
.flex-grow-1{ flex-grow: 1; }

.embed-responsive-cover {
    height: 100%;
}
.embed-responsive-cover .embed-responsive-item {
    width: auto;
    min-height: 100%;
    padding: 0;
    overflow: hidden;
}

.font-bold {
    font-family: var(--font-default-bold);
}

.test {
    background: green;
}

.test3 {
    color: yellow;
}

:target {
    padding-top: calc(60rem/16);
    margin-top: calc(-60rem/16);
    @media screen and (min-width: 768px) {
        padding-top: calc(82rem/16);
        margin-top: calc(-82rem/16);
    }
}

.object-fit-contain {
    object-fit: contain;
}

.object-fit-cover {
    object-fit: cover;
}

.whitespace-normal{
    white-space: normal;
}

/* opacity */
.opacity-0 {
    opacity: 0;
}

.opacity-50 {
    opacity: .5;
}

.opacity-100 {
    opacity: 1;
}