.close.modal-content__close {
    color: var(--color-primary);
    opacity: 1;
    z-index: 9;
    position: absolute;
    top:0;
    right:0;
    padding: calc(20rem/16);
}
.modal {
    z-index: 10050;
}
.modal-backdrop {
    z-index: 10030;
}

.modal__search-btn {
    font-size: calc(24rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
}