:root {
    --main-nav-height: calc(78rem/16);
    --nav-height: calc(115rem/16);
}
.main-nav {
    padding:0;
    background-color:#fff;
    z-index: 9999;
    position: relative;
}
.main-nav__collapse {
    justify-content:center;
}
.main-nav__brand-img {
    position: absolute;
    top:0;
    left:0;
    height: calc(46rem/16);
    padding-left: calc(40rem/16);
    transition: transform 0.2s ease;
    @media screen and (max-width: 1440px) and (min-width: 1200px){
        position: relative;
        height: calc(32rem/16);
        padding-left: calc(20rem/16);
    }
    @media screen and (max-width: 1199px) and (min-width: 768px){
        position: relative;
        height: calc(28rem/16);
        padding-left: calc(20rem/16);
    }
}
.is-affix .main-nav__brand-img {
    transform: translateY(calc(20rem/16));
    @media screen and (max-width: 1440px){
        transform:none;
    }
}
.main-nav__nav {
    @media screen and (min-width: 768px) {
        padding: 0 calc(30rem/16);
    }
}
.main-nav__nav-link {
    font-size: calc(18rem/16);
    letter-spacing: calc(1rem/16);
    line-height: calc(22/18);
    padding: calc(30rem/16) calc(20rem/16);
    text-align:center;
    display:block;
    @media screen and (max-width: 1440px) and (min-width: 768px){
        padding: calc(30rem/16) calc(12rem/16);
        font-size: calc(15rem/16);
    }
}
.main-nav.is-affix {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index: 9999;
    background-color:#fff;
}
.main-nav__nav-item.active > .main-nav__nav-link, .main-nav__nav-item.is-open > .main-nav__nav-link  {
    color: var(--color-primary);
}
.main-nav__nav-item.is-open:after, .main-nav__nav-item:hover:after  {
    @media screen and (min-width: 768px) {
        content:'';
        height: calc(2rem/16);
        width: calc(100% - calc(40rem/16));
        background-color: var(--color-primary);
        z-index: 999;
        position: relative;
        display: block;
        margin: calc(-2rem/16) auto 0;
    }
}
.main-nav__search-btn {
    position: absolute;
    right:0;
    top:0;
    margin-right: calc(35rem/16);
    height: calc(60rem/16);
    width: calc(60rem/16);
    font-size: calc(22rem/16);
    display: flex;
    justify-content:center;
    align-items:center;
    @media screen and (max-width: 1440px) and (min-width: 768px){
        position: relative;
        margin-right: calc(10rem/16);
    }
}
.main-nav__search-btn-text {
    display:none;
}
.is-affix .main-nav__search-btn {
    transform: translateY(calc(10rem/16));
    @media screen and (max-width: 1440px) {
        transform:none;
    }
}
.container.nav__container {
    max-width:calc(1300rem/16);
}

.main-nav__sub-nav--flex-center {
    justify-content: center !important;
}