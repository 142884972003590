.custom-select__wrapper {
    position:relative;
    width: 100%;
}
.custom-select__wrapper select {
    appearance: none;
    padding-right: 2rem;
}
.custom-select__wrapper select::-ms-expand {
    display: none;
}
.form-group .custom-select__icon {
    position:absolute;
    right:calc(8rem/16);
    font-size:calc(10rem/16);
    color:var(--color-text-default);
    pointer-events:none;
    top: 50%;
    transform: translateY(-50%);
}
.custom-select--simple .custom-select__wrapper{
    width: auto;
    display:inline-flex;
}
.custom-select--simple .form-control {
    color: var(--color-primary);
    border:none;
    margin-right: calc(10rem/16);
    padding-right: calc(20rem/16);
    font-size: calc(14rem/16);
}