.media-slider__embed {
    padding-top: calc(267 / 397 * 100%);
}
.media-slider__item {
    max-width: calc(397rem/16);
}
.media-slider__arrow-container {
    display: flex;
    justify-content: flex-end;
    margin-top: calc(25rem/16);
    @media screen and (max-width: 767px) {
        justify-content: center;
    }
}
.media-slider__arrow-container .arrow-next {
    margin-left: calc(20rem/16);
}