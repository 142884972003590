.filter-box {
    background-color:#fff;
    margin-bottom: calc(10rem/16);
    position: relative;
    padding: 1.5625rem 2.5rem;
    @media screen and (max-width: 767px) {
        margin-bottom: calc(0rem/16);
        padding: 0;
    }
}
.filter-box:before {
    content:'';
    position: absolute;
    width:95%;
    height:calc(100rem/16);
    bottom:calc(0rem/16);
    left:0;
    right:0;
    margin:0 auto;
    z-index: -1;
}