.interests-accordion {
    display: flex;
    flex-direction: column;
    row-gap: calc(10rem/16);
}

.interests-accordion__item {
    background-color: var(--color-light-grey);
}

.interests-accordion__header {
    padding: calc(20rem/16);
}

.interests-accordion__body {
    padding: calc(20rem/16) calc(40rem/16) calc(30rem/16);
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: calc(20rem/16);
        right: calc(20rem/16);
        height: calc(1rem/16);
        background-color: var(--color-border-grey);
    }
}

.interests-accordion__title-icon {
    font-size: calc(36rem/16);
    margin-right: calc(10rem/16);
    color: var(--color-dark-grey);
    transition: color 300ms ease;
}

.interests-accordion__title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: calc(.5rem/16);
}

.interests-accordion__title {
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    color: var(--color-text-default);
    transition: color 300ms ease;
}

.interests-accordion__checkbox {
    padding-left: calc(40rem/16);
}

.interests-accordion__checkbox .custom-checkbox__box {
    margin: 0 calc(20rem/16) 0 0;
    top: 0;
}

.interests-accordion__option-checkbox .custom-checkbox__text {
    font-family: var(--font-default-bold);
    color: var(--color-text-default);
    transition: color 300ms ease;
}

.interests-accordion__option-checkbox.is-checked .custom-checkbox__text {
    color: var(--color-primary);
}

.interests-accordion__toggle-icon {
    transform: rotate(180deg);
    transition: transform 300ms ease;
}

.interests-accordion__header-link.collapsed .interests-accordion__toggle-icon {
    transform: rotate(0);
}

.interests-accordion__header-link.is-active .interests-accordion__title,
.interests-accordion__header-link.is-active .interests-accordion__title-icon {
    color: var(--color-primary);
}