.table-fixed-first-col th:first-child, .table-fixed-first-col td:first-child {
    position:sticky;
    left: calc(-1rem/16);
    background-color:var(--color-light-grey);
    border-bottom: calc(2rem/16) solid #FFF;
    @media screen and (max-width: 767px) {
        hyphens: auto;
        font-size: calc(12rem/16);
        line-height:1;
    }
}
@media screen and (max-width: 767px) {
    .table-fixed-first-col--mobile {
        font-size: calc(12rem/16);
    }
    .table-fixed-first-col--mobile th, .table-fixed-first-col--mobile td {
        padding: calc(7rem/16);
    }
    .table-fixed-first-col--mobile th:first-child, .table-fixed-first-col--mobile td:first-child {
        position:sticky;
        left: calc(-1rem/16);
        background-color:var(--color-light-grey);
        border-bottom: calc(2rem/16) solid #FFF;
    }
    .table-fixed-first-col--mobile .icon {
        position: relative;
        z-index: -1;
    }
}
