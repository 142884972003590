.comparison-item {
    display: grid;
    grid-template-rows: auto 1fr auto;
    @media screen and (max-width: 767px) {
        row-gap: calc(20rem/16);
    }
    @media screen and (min-width: 768px) {
        width: calc(95rem/16);
    }
}

.comparison-item--active {
    flex-grow: 1;
}

.comparison-item:not(.comparison-item--active, .comparison-item--selected) {
    flex-shrink: 1;
    &:hover {
        cursor: pointer;
    }
}

.comparison-item__content {
    position: relative;
    @media screen and (min-width: 768px) {
        padding: calc(30rem/16);
    }
}

.comparison-item__image {
    margin-bottom: calc(10rem/16);
    @media screen and (min-width: 768px) {
        max-width: calc(210 / 1920 * 100vw);
    }
}

.comparison-item-embed:before {
    padding-top: calc(185 / 210 * 100%);
}

.comparison-item__title {
    font-size: calc(16rem/16);
    line-height: calc(25rem/16);
    letter-spacing: calc(1rem/16);
    font-family: var(--font-default);
    margin-bottom: calc(20rem/16);
}

.comparison-item__price {
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    line-height: calc(25rem/16);
    letter-spacing: calc(.5rem/16);
    margin-bottom: calc(20rem/16);
}

.comparison-item__delete {
    font-size: calc(18rem/16);
    color: var(--color-danger);
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    @media screen and (min-width: 768px) {
        top: calc(30rem/16);
        right: calc(30rem/16);
    }
}

.comparison-item__delete:focus {
    outline: none;
}

.comparison-item__btn {
    display: flex;
    align-items: center;
    column-gap: calc(10rem/16);
    justify-content: center;
}

.comparison-item__btn .icon {
    font-size: calc(18rem/16);
}

.comparison-item__btn:disabled {
    background-color: var(--color-light-grey);
    color: var(--color-dark-grey);
    cursor: not-allowed;
    pointer-events: none;
    border: 1px solid var(--color-light-grey);
}

.comparison-item__image--placeholder {
    background-color: var(--color-light-grey);
}

.comparison-item__title--placeholder {
    height: calc(20rem/16);
    background-color: var(--color-light-grey);
}

.comparison-item__title--placeholder + .comparison-item__title--placeholder {
    margin-top: calc(5rem/16);
    width: 87.5%;
}

.comparison-item__price--placeholder {
    width: calc(60rem/16);
    height: calc(20rem/16);
    background-color: var(--color-light-grey);
}

.comparison-item__search--placeholder {
    border-bottom: 1px solid var(--color-border-grey);
    height: calc(36rem/16);
    color: var(--color-border-grey);
    display: flex;
    align-items: flex-end;
}

.comparison-item__search--placeholder .icon {
    font-size: calc(12rem/16);
    padding-bottom: calc(11rem/16);
}

.comparison-item__search-icon {
    cursor: pointer;
    padding: .5rem;
}