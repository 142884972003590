.icon-teaser-area__container {
    display: grid;
    gap: calc(10rem/16);
    margin-bottom: 7.5rem;
    grid-auto-rows: 1fr;
}

.icon-teaser-area__headline {
    margin-top: 7.5rem;
}

.icon-teaser-area__container--max-cols-6 {
    grid-template-columns: repeat(1, 1fr);
}

.icon-teaser-area__container--max-cols-5 {
    grid-template-columns: repeat(1, 1fr);
}

.icon-teaser-area__container--max-cols-4 {
    grid-template-columns: repeat(1, 1fr);
}

@media screen and (min-width: 567px) and (max-width: 767px) {
    .icon-teaser-area__container--max-cols-6 {
        grid-template-columns: repeat(2, 1fr);
    }

    .icon-teaser-area__container--max-cols-5 {
        grid-template-columns: repeat(2, 1fr);
    }

    .icon-teaser-area__container--max-cols-4 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .icon-teaser-area__container--max-cols-6 {
        grid-template-columns: repeat(5, 1fr);
    }

    .icon-teaser-area__container--max-cols-5 {
        grid-template-columns: repeat(4, 1fr);
    }

    .icon-teaser-area__container--max-cols-4 {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 992px) {
    .icon-teaser-area__container--max-cols-6 {
        grid-template-columns: repeat(6, 1fr);
    }

    .icon-teaser-area__container--max-cols-5 {
        grid-template-columns: repeat(5, 1fr);
    }

    .icon-teaser-area__container--max-cols-4 {
        grid-template-columns: repeat(4, 1fr);
    }
}

.icon-teaser {
    padding: 1rem 1.25rem 1.15rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    box-shadow: 0 0.125rem calc(50rem / 16) 0 rgba(31, 31, 31, .25);
    align-items: center;
    background-color: white;
    justify-content: center;

    &:hover {
        .icon-teaser__icon {
            color: black;
        }
    }
}

.icon-teaser__icon {
    font-size: 3.75rem;
    color: var(--color-primary);
    transition: color .25s ease;
}

.icon-teaser__icon--simple {
    scale: .75;
}

.icon-teaser__link {
    font-family: var(--font-default);
    font-size: calc(16rem/16);
    display: flex;
    align-items: center;
    transition: .25s ease color;
    justify-content: center;
    color: black;
    min-height: calc(56rem/16);

    .icon {
        color: var(--color-primary);
    }
}
