:root {
    --range-slider-handle-size: 1.2rem;
}

.range-slider {
    padding-bottom: calc(var(--range-slider-handle-size) / 2);
    height: calc(76rem/16);
}
.range-slider .noUi-horizontal {
    height: calc(4rem/16);
    padding-left: calc(var(--range-slider-handle-size) / 2);
    padding-right: calc(var(--range-slider-handle-size) / 2);
}
.range-slider .noUi-target {
    box-shadow: none;
    border: none;
    background: var(--color-dark-grey);
}
.range-slider .noUi-connect {
    background: var(--color-primary);
}
.range-slider .noUi-horizontal .noUi-handle {
    top: calc(var(--range-slider-handle-size) / 2 * -1 + .125rem);
}

.range-slider .noUi-handle,
html:not([dir=rtl]) .range-slider .noUi-handle {
    width: var(--range-slider-handle-size);
    height: var(--range-slider-handle-size);
    border-radius: 50%;
    border: calc(5rem/16) solid var(--color-primary);
    background: #ffffff;
    box-shadow: none;
    right: calc(var(--range-slider-handle-size) / 2 * -1);
}
.range-slider .noUi-handle:after,
.range-slider .noUi-handle:before {
    display: none;
}
