.quickfilter:not(:last-child) {
    margin-right: .625rem;
}
.quickfilter {
    text-transform: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom:  .625rem;
}
.quickfilter__icon {
    display: inline-flex;
    align-self: center;
    padding-bottom: 1px;
    justify-self: center;
    justify-content: center;
    padding-right: 5px;
}