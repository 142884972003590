.slider__arrow {
    background-color: transparent;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    height: calc(51rem/16);
    width: calc(63rem/16);
    display: flex;
    justify-content:center;
    align-items:center;
    font-size: calc(20rem/16);
    @media screen and (max-width: 767px) {
        width: calc(51rem/16);
        height: calc(41rem/16);
        font-size: calc(13rem/16);
    }
}
.slider__arrow--small {
    width: calc(48rem/16);
    height: calc(48rem/16);
    @media screen and (max-width: 767px) {
        width: calc(40rem/16);
        height: calc(40rem/16);
    }
}
.slider__arrow.slick-disabled {
    opacity: 0.5;
    pointer-events:none;
}
.slider__slide-count {
    text-align:center;
    color: var(--color-primary);
    margin-top: calc(15rem/16);
}
.slider--hide-dots .slick-dots {
    visibility: hidden;
    display: none;
}