.breadcrumb-item + .breadcrumb-item:before {
    content: var(--icon-chevron-right);
    font-size: calc(12rem/16);
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0);
    transform: translate(0);
}
.breadcrumb {
    margin-bottom:0;
}