.dashboard-area__teasers {
    margin: calc(40rem/16) 0;
}

.dashboard-area__teasers--incomplete {
    margin-bottom: calc(20rem/16);
}

.dashboard-area__logout {
    color: var(--color-primary);
    text-transform: uppercase;
    font-size: calc(13rem/16);
    display: flex;
    align-items: center;
    column-gap: calc(12rem/16);
    & .icon {
        font-size: calc(16rem/16);
        transition: transform 300ms ease;
    }
}

.dashboard-area__logout:hover {
    color: var(--color-primary-dark);
    & .icon {
        transform: translateX(calc(8rem/16));
    }
}

.dashboard-area__incomplete-notice {
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    display: flex;
    align-items: center;
    column-gap: calc(10rem/16);
    margin-bottom: calc(60rem/16);
    & .icon {
        font-size: calc(24rem/16);
        color: var(--color-info);
    }
}

