.commerceConnector__online-result {
    margin-top: .5rem;
}
.commerceConnector__storeitem{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin:0;
    border: 1px solid #ccc;
    height: 100%;
}
.commerceConnector__storeitem a{
    color:var(--color-text-default);
    display: block;
    padding:.5rem;

    @media screen and (min-width: 768px) {
        padding:1.25rem 1.25rem .5rem 1.25rem;
    }
}
.commerceConnector__storeitem a:hover,
.commerceConnector__storeitem a:focus{
    color: var(--color-primary);
}

.commerceConnector__online-result .pagination {
    margin-top:1.5rem;
}

/* flag btn */
.commerceConnector__country .commerceConnector__country-txt {
    border-bottom:1px solid transparent;
}
.commerceConnector__country.country-is-active .commerceConnector__country-txt {
    border-color: #000;
}
.commerceConnector__country .flag {
    position: relative;
    top:.25rem;
    margin-right:.5rem;
}
.commerceConnector__no-results {
    font-family: var(--font-default-bold);
    margin-top:2rem;
}



