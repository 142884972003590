:root {
    --portal-hero-top-spacing: calc(70rem/16);
}
.content-hero {
    position:relative;
}

.content-hero__embed{
    height: 100vh !important;

    @media screen and (max-width: 767px) {
        height: auto;
    }
}

.content-hero__embed:before {
    padding-top:  calc(100vh - var(--nav-height));
    @media screen and (max-width: 767px) and (orientation: landscape) {
        padding-top: 150vh;
    }
    @media screen and (max-width: 767px) and (orientation: portrait) {
        padding-top: 80vh;
    }
}
.content-hero__content {
    position: absolute;
    top:0;
    width:100%;
    height:100%;
    z-index: 1;
    color:#fff;
    text-align:left;
    @media screen and (max-width: 1800px) {
        padding: calc(40rem/16) 0;
    }
}
.content-hero__subtitle {
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    /* 24px @ 992px increasing to 30px @ 1920px */
    @media (min-width: 992px) {
        font-size: calc(1.5rem + ((1vw - 0.62rem) * 0.6466));
        /* Where: * 0.6466 = 100 * font_Size_Difference / viewport_Width_Difference */
    }
    /* Prevent font scaling beyond this breakpoint */
    @media (min-width: 1920px) {
        font-size: calc(30rem / 16);
    }
}
.content-hero__subtitle:before {
    content:'';
    background-color: #fff;
    height:calc(2rem/16);
    width: calc(74rem/16);
    display:block;
    margin: calc(26rem/16) auto;
    @media screen and (max-width: 767px) {
        margin: calc(15rem/16) auto;
    }
}
.content-hero__title {
    line-height: calc(80/70);
    letter-spacing: calc(3rem/16);
    text-transform:uppercase;
    font-size: calc(30rem/16);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 1800px) {
        margin-bottom: calc(30rem/16);
    }
    @media (min-width: 992px) {
        font-size: calc(2.625rem + ((1vw - 0.62rem) * 1.9397));
        /* Where: * 1.9397 = 100 * font_Size_Difference / viewport_Width_Difference */
    }
    /* Prevent font scaling beyond this breakpoint */
    @media (min-width: 1920px) {
        font-size: calc(60rem / 16);
    }
}
.content-hero__image {
    background-size:cover;
    background-position: center;
}
.content-hero__inner {
    backdrop-filter: blur(1px);
    padding: calc(20rem/16);
}
.content-hero__inner--light {
    background-color: rgba(255,255,255,0.4);
    color: #000;
}
.content-hero__inner--light .content-hero__subtitle:before {
    background-color: #000;
}
.content-hero__inner--dark {
    background-color: rgba(0,0,0,0.4);
    color: #fff;
}