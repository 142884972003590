.dot-navigation{
    position: fixed;
    right: 1rem;
    z-index: 2;


    @media screen and (max-width: 767px) {
        display: none;
    }
}

.dot-navigation ul {
    list-style: none;
    height: 100vh;
    padding: 0;
}

.dot-navigation li {
    position: relative;
}

.dot{
    height: 0.6rem;
    width: 0.6rem;
    padding: 0;
    display: block;
    border-radius: 50%;
    background-color: var(--color-dark-grey);
    transition: background-color ease-out 0.2s, border 0.2s ease-out;
}

.dot-navigation li:not(:last-of-type){
    margin-bottom: 0.8rem;
}

.dot--active .dot{
    height: 0.8rem;
    width: 0.8rem;
    background: transparent;
    border: 2px solid var(--color-primary);
}

.nav-title__container{
    width: 11rem;
}

.nav-title {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transition: .2s;
    background: var(--color-green);
    color: #fff;
    padding: .3rem 1rem;
    border-radius: 5px;
    right: 1.5rem;
    white-space: nowrap;
    top: -100%;
}

.show-nav-text{
    opacity: 1 !important;
    visibility: visible !important;
}

