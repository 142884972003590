.product-teaser__embed {
    padding-top: calc(311 / 353 * 100%);
}

.product-teaser {
    margin-top: calc(20rem/16);
    background-color:#fff;
    margin-bottom: calc(40rem/16);
    position:relative;
    cursor:pointer;
    transition: transform 0.2s ease;
}
.product-teaser:hover {
    transform: translateY(calc(-20rem/16));
}
.product-teaser__body {
    padding: calc(19rem/16) calc(35rem/16);
    box-shadow: 0 calc(15rem/16) calc(30rem/16) 0 rgba(87,35,129,0.06);
}
.product-teaser__body.product-teaser__body--small {
    padding: calc(12rem/16) calc(16rem/16);
}
.product-teaser__title{
    text-align:center;
    font-size: calc(16rem/16);
    letter-spacing: calc(1.14rem/16);
    line-height: calc(25/16);
}
.product-teaser:before {
    content: '';
    position: absolute;
    height: calc(6rem/16);
    width: 100%;
    z-index: 9;
}
.product-teaser__feature {
    background-color:#fff;
    z-index: 2;
    padding-bottom: calc(7rem/16);
    position: relative;
    display: inline-block;
    padding-left: calc(6rem/16);
    margin-right: calc(2rem/16);
    margin-bottom: calc(2rem/16);
}
.product-teaser__feature-wrapper {
    position: absolute;
    top:0;
    width: 100%;
    display:flex;
    flex-wrap: wrap;
}
.product-teaser--green:before { background-color: var(--color-green);}
.product-teaser--light-green:before { background-color: var(--color-light-green);}
.product-teaser--middle-green:before { background-color: var(--color-middle-green);}

.product-teaser--purple:before { background-color: var(--color-purple);}
.product-teaser--light-purple:before { background-color: var(--color-light-purple);}
.product-teaser--middle-purple:before { background-color: var(--color-middle-purple);}

.product-grid__product-teaser__img-disappear {
    opacity: 1;
    transition: opacity 0.2s ease-in;
}

.product-grid__product-teaser:hover .product-grid__product-teaser__img-disappear {
    @media screen and (min-width:768px){
        opacity: 0;
    }
}

.product-grid__product-teaser__img--hide-mobile {
    @media screen and (max-width:767px){
        display: none;
    }
}