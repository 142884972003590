.logo-area{
    background-color: var(--color-light-grey);
    padding: calc(40rem/16) 0;
}

.logo-area__logo-top{
    height: calc(52rem/16);
    margin-bottom: calc(20rem/16);
    text-align: center;
    margin-top: calc(10rem/16);
    transition: transform 0.25s ease-in-out;
}


@media screen and (min-width: 768px){
    .logo-area__logo-top{
        height: calc(92rem/16);
        margin-bottom: calc(40rem/16);
        margin-top: calc(20rem/16);
    }
}

.logo-area__logos{
    display: flex;
    justify-content: center;
    padding: 0 calc(20rem/16);
}
.logo-area__logo-small {
    height: calc(28rem/16);
    margin: calc(10rem/16) calc(12rem/16) calc(10rem/16);
    transition: transform 0.25s ease-in-out;
}


@media screen and (min-width: 768px){
    .logo-area__logo-small {
        height: calc(56rem/16);
        margin: calc(20rem/16) calc(20rem/16) calc(20rem/16);
    }
}

.logo-area a .logo-area__logo-small:hover{
    transform: scale(1.07);
    transition: transform 0.25s ease-in-out;
}
.logo-area a .logo-area__logo-top:hover{
    transform: scale(1.07);
    transition: transform 0.25s ease-in-out;
}