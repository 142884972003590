.dealer-area__search {
    padding: calc(20rem/16) calc(40rem/16);
    background-color: var(--color-light-grey);
    margin-bottom: calc(40rem/16);
}

.dealer-area__location-button {
    background-color: transparent;
    border: none;
    color: var(--color-primary);
    padding: 0;
    font-size: calc(12rem/16);
    line-height: calc(20/12);
    position: absolute;
    right: 0;
    top: 100%;
}

.dealer-area__collapse-btn {
    background-color: transparent;
    border: none;
    padding: 0;
    text-transform: none;
    font-family: var(--font-default-bold);
    display: flex;
    align-items: center;
    line-height: 1;
    column-gap: calc(10rem/16);
    margin: calc(20rem/16) 0;
}
.dealer-area__collapse-btn:hover,
.dealer-area__collapse-btn:focus,
.dealer-area__collapse-btn:active {
    text-decoration: none;
    border: none;
    outline: none;
}

.dealer-area__collapse-btn .icon {
    transition: transform ease 0.3s;
    display: inline-block;
    transform: rotate(180deg);
}

.dealer-area__collapse-btn.collapsed .icon {
    transform: rotate(0);
}

.dealer-area__dealer-selected {
    margin-bottom: calc(60rem/16);
}

.dealer-area__dealer-top,
.dealer-area__dealer-bottom {
    padding: calc(20rem/16) calc(40rem/16);
}

.dealer-area__dealer-top {
    background-color: var(--color-light-grey);
}

.dealer-area__dealer-bottom {
    border: 1px solid var(--color-grey);
}

.dealer-area__dealer-subtitle {
    font-size: calc(12rem/16);
    display: flex;
    align-items: center;
    line-height: 1;
    column-gap: calc(4rem/16);
}

.dealer-area__dealer-title {
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    line-height: calc(25/16);
    letter-spacing: 0;
}

.dealer-area__dealer-subtitle .icon {
    font-size: calc(18rem/16);
    color: var(--color-primary);
}