.certificates-teaser {
    font-size: calc(16rem/16);
    margin-top: calc(20rem/16);
    background-color:#fff;
    padding:1.5rem;
    margin-bottom: calc(40rem/16);
    position:relative;
    cursor:pointer;
    transition: transform 0.2s ease;
    box-shadow: 0 calc(15rem/16) calc(30rem/16) 0 rgba(87,35,129,0.06);
    @media screen and (min-width: 768px) {
        font-size: calc(17rem/16);
    }
    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        padding:1rem;
    }
}
.certificates-teaser:hover {
    transform: translateY(calc(-20rem/16));
}
.certificates-teaser__title,
.certificates-teaser__norm,
.certificates-teaser__flags {
    margin:1rem 0;
    position: relative;
}
.certificates-teaser__title {
    @media screen and (min-width: 768px) {
        height:3.375rem;
    }
    @media screen and (max-width: 767px) {
        margin-top:0;
    }
}
.certificates-teaser__flags {
    height:3.5rem;
    margin-top:.75rem;
    overflow: hidden;
}
.certificates-teaser__flags.expanded {
    height:auto;
    overflow: visible;
}
.certificates-teaser__norm {
    height:5rem;
    overflow: hidden;
    text-transform: uppercase;
}
.certificates-teaser__norm.expanded {
    height:auto;
    overflow: visible;
}
.certificates-teaser .flag {
    margin-right: .125rem;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}
.certificates-teaser__more {
    position: absolute;
    right:.125rem;
    bottom:.25rem;
    display: inline-block;
    width:22px;
    height:17px;
    background: var(--color-primary);
    text-indent: -19999px;
    transition: all 0.15s ease-in-out;

    @media screen and (min-width: 1800px) {
        right:.5rem;
    }
}

.certificates-teaser__more:hover,
.certificates-teaser__more:focus {
    background: var(--color-primary-dark);
}
.certificates-teaser__more:after {
    position: absolute;
    left:50%;
    top:50%;
    transform: translateY(-50%) translateX(-50%);
    content:'';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 6px 0 6px;
    border-color:#fff transparent transparent transparent;
}
.expanded .certificates-teaser__more:after {
    transform: translateY(-50%) translateX(-50%) scale(-1);
}

.certificates-teaser__btn {
    padding: .9375rem .75rem;
    font-size: .625rem;
    margin: 0 .125rem .25rem 0;
}
.certificates-teaser__btn>.icon {
    margin-right: .25rem;
}