.vertical-title {
    position: absolute;
    transform: rotate(-90deg) translateX(-100%) translateY(-100%);
    transform-origin: left top;
    top: 0;
    padding: 0 0.3rem 2.5rem 0;
    margin:0;
    height:calc(100rem/16);
    max-width: calc(210rem/16);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: calc(16rem/16);
    @media screen and (max-width: 767px) {
        padding: 0 0.3rem calc(10rem/16) 0;
        font-size: calc(12rem/16);
        height: calc(50rem/16);
        align-items: flex-start;
    }
}
.vertical-title__wrapper {
    position:relative;
    padding-left: calc(30rem/16);
    min-height: calc(140rem/16);
    @media screen and (max-width: 767px) {
        padding-left: calc(50rem/16);
    }
}

.vertical-title__button-wrapper {
    position:relative;
    text-align: center;
    width: 100%;
}