.dealer-search {
    display: grid;
    grid-template-areas:
        "filter map"
        "list map";
    grid-template-columns: 1fr 3fr;
    grid-template-rows: auto 1fr;
}

.dealer-search__map-filter {
    grid-area: filter;
}

.dealer-search__map-list {
    grid-area: list;
}

.dealer-search__map-map {
    grid-area: map;
}

@media (max-width: 768px) {
    .dealer-search {
        grid-template-areas:
            "filter"
            "map"
            "list";
        grid-template-columns: 1fr;
    }
}
