.meta-footer__list {
    padding: calc(18rem/16) 0;
    font-size: calc(13rem/16);
    @media screen and (max-width: 767px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}
.meta-footer__elements-logo {
    display: flex;
    justify-content:center;
    width: calc(170rem/16);
    @media screen and (max-width: 767px) {
        margin:0 auto;
        padding: calc(10rem/16);
    }
}
.meta-footer__elements-logo img {
    width: calc(170rem/16);
}
.meta-footer__list-item {
    @media screen and (max-width: 767px) {
        padding: calc(8rem/16);
    } 
}