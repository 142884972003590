.hero--with-main-title-block__embed {
    padding-top: calc(820/1072 * 100%);
}
.hero--with-main-title-block {
    position:relative;
}
.hero--with-main-title-block__image:before {
    background: linear-gradient(270deg,hsl(0, 0%, 100%),hsla(0,0%,100%,0.1));
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 2;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        background: linear-gradient(270deg,hsl(0, 0%, 100%),hsla(0,0%,100%,0.6));
    }
    @media screen and (max-width: 767px) {
        background: linear-gradient(0deg,hsla(0,0%,100%,.9),hsla(0,0%,100%,0));
    }
}

.hero--with-main-title-block__image.hero--with-main-title-block__image--right-gradient:before {
    background: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
    left: 35%;
}

.hero--with-main-title-block__content {
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left:0;
    z-index: 99;
    padding-bottom: calc(100rem/16);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding-bottom: calc(30rem/16);
        padding-top: calc(20rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-bottom:0;
        position:relative;
        margin-top: -20%;
        padding-left: calc(15rem/16);
        padding-right: calc(15rem/16);
        margin-bottom: calc(30rem/16);
    }
}