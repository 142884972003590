.icon-checkbox {
    padding: 0;
}

.icon-checkbox__label {
    background-color: var(--color-light-grey);
    aspect-ratio: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: calc(40rem/16) calc(10rem/16) calc(10rem/16);
    row-gap: calc(30rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(60rem/16) calc(15rem/16) calc(15rem/16);
        row-gap: calc(45rem/16);
    }
}

.icon-checkbox__box {
    left: calc(10rem/16);
    top: calc(10rem/16);
    @media screen and (min-width: 768px) {
        left: calc(15rem/16);
        top: calc(15rem/16);
    }
}

.icon-checkbox__icon {
    font-size: calc(40rem/16);
    color: var(--color-dark-grey);
    @media screen and (min-width: 768px) {
        font-size: calc(60rem/16);
    }
}

.icon-checkbox__text {
    font-family: var(--font-default-bold);
    letter-spacing: calc(.5rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
    }
}