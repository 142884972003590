.product-spec__grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @media screen and (min-width: 768px){
        flex-direction: row;
    }
}
.product-spec__item {
    margin: calc(2.5rem/16);
    background-color: #3D4B5605;
    padding: 2rem;
    color: var(--color-secondary);
    @media screen and (min-width: 768px){
        width: calc(20% - (calc(5rem/16)));
    }
}
.product-spec__description {
    font-size: calc(12rem/16);
}
.product-spec__value{
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
}