.main-nav__sub-nav {
    position: absolute;
    top: var(--nav-height);
    width: 100%;
    background-color:#fff;
    z-index: 3;
    left:0;
    display:block;
    min-height: calc(458rem/16);
    padding: calc(40rem/16) 0 calc(20rem/16);
    visibility:hidden;
    pointer-events: none;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        top: calc(102rem/16);
    }
    @media screen and (min-width: 768px) {
        position: fixed;
        opacity: 0;
        transform: translateX(100%);
        transition: opacity 600ms, visibility .001ms 600ms, transform 0.001ms 600ms;
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.main-nav__sub-nav:after {
    box-shadow: 0 3.125rem 4.125rem -4.125rem rgba(31,31,31,.25);
    content: "";
    position: absolute;
    z-index: -2;
    bottom: 0;
    width: 100%;
    margin: 0 auto;
    height: calc(var(--main-nav-height) + var(--nav-height));
    left: 0;
    right: 0;
    pointer-events: none;
}
.is-affix .main-nav__sub-nav {
    top: var(--main-nav-height);
}
.nav-item.is-open > .main-nav__sub-nav, .main-nav__sub-nav.show-menu {
    visibility: visible;
    pointer-events: auto;
    z-index: 101;
    transform: translateX(0);
    @media screen and (max-width: 767px) {
        display: block;
        transition: transform 120ms;
    }
    @media screen and (min-width: 768px) {
        opacity: 1;
        transition: opacity 300ms, visibility 120ms;
    }
}
/*
@media screen and (min-width: 768px) {
    .nav-item:hover > .main-nav__sub-nav {
        visibility: visible;
        pointer-events: auto;
        z-index: 101;
        transform: translateX(0);
        opacity: 1;
        transition: opacity 120ms;
    }
}*/
.main-nav__sub-nav-title {
    font-size: calc(18rem/16);
    letter-spacing: calc(1rem/16);
    line-height: calc(23/18);
    font-family: var(--font-default-bold);
    text-align:left;
    padding:0;
    @media screen and (max-width: 767px) {
        color: var(--color-text-default);
        font-size: calc(14rem/16);
    }
}
.main-nav__sub-nav-title:after {
    @media screen and (min-width: 768px) {
        content:'';
        width: calc(46rem/16);
        height: calc(1rem/16);
        background-color: var(--color-primary);
        margin: calc(18rem/16) 0 calc(20rem/16);
        display:block;
        z-index: 3;
    }
}
.main-nav__sub-nav-item {
    font-size: calc(16rem/16);
    letter-spacing: calc(0.34rem/16);
    line-height: calc(22/16);
    padding: calc(10rem/16) 0;
}
.main-nav__sub-nav-item.active {
    color: var(--color-primary);
}
.main-nav__sub-nav-item.active:after {
    content:'';
    width: calc(25rem/16);
    height: calc(2rem/16);
    padding-right: calc(8rem/16);
    display:inline-block;
    background-color: var(--color-primary);
}
.main-nav__sub-nav-item-link:hover, .main-nav__sub-nav-title:hover, .main-nav__sub-nav-title.active {
    color: var(--color-primary);
}
.main-nav__sub-nav-item--light-green:after { background-color: var(--color-light-green); }
.main-nav__sub-nav-item--middle-green:after { background-color: var(--color-middle-green); }
.main-nav__sub-nav-item--green:after { background-color: var(--color-green); }

.main-nav__sub-nav-item--light-purple:after { background-color: var(--color-light-purple); }
.main-nav__sub-nav-item--middle-purple:after { background-color: var(--color-middle-purple); }
.main-nav__sub-nav-item--purple:after { background-color: var(--color-purple); }