.collapse-link__more,
.collapsed .collapse-link__less {
    display: none;
}
.collapsed .collapse-link__more {
    display: block;
}
.collapse-link__icon {
    transition: transform 200ms ease-in-out;
    transform: rotate(180deg);
}
.collapsed .collapse-link__icon {
    transform: rotate(0);
}