.info-slide {
    height: calc(450rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    @media screen and (max-width: 767px) {
        height: auto;
    }
}
.info-slide__inner {
    background-color:#fff;
    padding: calc(50rem/16) calc(60rem/16);
    box-shadow: 0 calc(15rem/16) calc(30rem/16) 0 rgba(87,35,129,0.05);
    @media screen and (max-width: 767px) {
        padding: calc(25rem/16);
    }
}
.info-slide__content {
    padding-top: calc(15rem/16);
    padding-bottom: calc(30rem/16);
}