.news-teaser__embed {
    padding-top: calc(321 / 482 * 100%);
}
.news-teaser__media {
    transition: transform 0.4s ease;
}
.news-teaser__embed:after {
    content:'';
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height:100%;
    background-color: rgba(55, 55, 55, 0.16);
    transition: opacity 0.3s ease;
}
.news-teaser:hover .news-teaser__embed:after {
    opacity:0;
}
.news-teaser:hover .news-teaser__media {
    transform: scale(1.04);
}
.news-teaser--transformed .news-teaser__embed {
    @media screen and (max-width: 767px) {
        padding-top: 115%;
    }
}
.news-teaser:hover .text-teaser__title {
    color: var(--color-primary);
}
.news-teaser--transformed .news-teaser-body__wrapper {
    margin-top: -30%;
}
.news-teaser__body {
    padding: calc(50rem/16) calc(10rem/16) calc(50rem/16) calc(50rem/16) ;
    background: #fff;
    box-shadow: 0 calc(15rem/16) calc(30rem/16) 0 rgba(87,35,129,0.05);
    margin-bottom: calc(30rem/16);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @media screen and (max-width: 1199px) {
        padding: calc(20rem/16) calc(24rem/16);
    }
}
.news-teaser--transformed .news-teaser__body {
    @media screen and (max-width: 767px) {
        width: 90%;
        margin: -30% auto calc(30rem/16);
        padding: calc(50rem/16) calc(24rem/16);
    }
}

.news-teaser-body__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}