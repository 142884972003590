:root {
    --container-max-width: calc(1500rem/16);
    --container-narrow-max-width: calc(830rem/16);
    --container-md-max-width: calc(990rem/16);
    --container-lg-max-width: calc(1240rem/16);
}

.container.container {
    max-width: var(--container-max-width);
    width: 100%;
}
.container.container-narrow {
    max-width: var(--container-narrow-max-width);
    width: 100%;
}
.container.container-md {
    max-width: var(--container-md-max-width);
    width: 100%;
}
.container.container-lg {
    max-width: var(--container-lg-max-width);
    width: 100%;
}
@media screen and (min-width: 1500px) {
    .container.container__move-right {
        /*margin-left: calc((100vw - var(--container-max-width)) / 2 + var(--row-gutter));*/
        max-width: calc(1500px + (100vw - var(--container-max-width)) / 2 + var(--row-gutter));
        padding:0;

        /* todo jenny fragen */
        margin-left: auto;
        margin-right: 0;
    }
}
@media screen and (min-width: 1500px) {
    .container.container__move-left {
        margin-right: calc((100vw - var(--container-max-width)) / 2 + var(--row-gutter));
        max-width: calc(1500px + (100vw - var(--container-max-width)) / 2 + var(--row-gutter));
        padding:0;
    }
}
@media screen and (max-width: 767px) {
    .container.container--no-padding-xs {
        padding:0;
    }
    .container--small-padding-xs {
        padding-left: calc(4rem/16);
        padding-right: calc(4rem/16);
    }
}
