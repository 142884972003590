.image-teaser-area__headline {
    font-size: calc(30rem/16);
    font-family: var(--font-default);
    text-align: left;
    margin-bottom: calc(20rem/16);
    margin-top: calc(50rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(120rem/16);
    }
}

.image-teaser {
    display: flex;
    row-gap: calc(24rem/16);
    flex-direction: column;
    /* margin-bottom: 3.5rem; */
    background-color: white;
    box-shadow: 0 0.125rem calc(50rem / 16) 0 rgba(31, 31, 31, .25);
    height: 100%;
}

.image-teaser__text,
.image-teaser__link {
    font-family: var(--font-default);
    font-size: calc(14rem/16);
}

.image-teaser__text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: calc(16rem/16);
}

.image-teaser__link {
    text-transform: uppercase;
    color: var(--color-primary);
    display: flex;
    align-items: center;
    transition: .25s ease color;
    padding-top: 1rem;
    letter-spacing: calc(1.86rem/16);
}

.image-teaser__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: calc(16rem/16);
    flex-grow: 1;
    padding-top: calc(16rem/16);
    padding-right: calc(24rem/16);
    padding-left: calc(24rem/16);
    padding-bottom: calc(24rem/16);
}