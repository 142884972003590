.checkbox-filter__label {
    position:relative;
}
.checkbox-filter {
    position: relative;
    cursor: pointer;
}
.checkbox-filter .custom-checkbox__box {
    display:none;
}
.checkbox-filter__input {
    position: absolute;
    left: calc(-19999rem/16);
    top: 0;
    transition: all .3s ease;
}
.checkbox-filter .custom-checkbox__text {
    border-radius: 0;
    padding: calc(13rem / 16) calc(35rem / 16);
    display: inline-block;
    border: calc(1rem/16) solid var(--color-primary);
    background-color: #fff;
    color: var(--color-primary);
    text-transform:uppercase;
    font-size: calc(13rem/16);
    letter-spacing: calc(1.8rem/16);
    line-height: calc(23/13);
    transition: color 0.2s ease, backgorund-color 0.2s ease;
    @media screen and (max-width: 767px) {
        width:100%;
        text-align:center;
    }
}
.checkbox-filter .checkbox-filter__input:checked + .custom-checkbox__box + .custom-checkbox__text {
    color: #fff;
    border-color:1px solid var(--color-primary);
    background-color: var(--color-primary);
    transition: all .3s ease;
}