.nav-tabs--outline-primary .nav-link {
    letter-spacing: calc(1.8rem/16);
    font-size: calc(13rem/16);
    line-height: calc(23/13);
    border-color: var(--color-primary);
    color: var(--color-primary);
    display:flex;
    justify-content:center;
    padding: calc(13rem/16) calc(30rem/16);
    text-align:center;
    margin-left: calc(10rem/16);
    margin-right: calc(10rem/16);
    transition: background-color 0.3s ease, color 0.3s ease;
    @media screen and (max-width: 767px) {
        margin: calc(5rem/16) 0;
        display:block;
        width: 100%;
    }
}
.nav-tabs--outline-primary .nav-item {
    @media screen and (max-width: 767px) {
        display:block;
        width: 100%;
    }
}
.nav-tabs--outline-primary .nav-link .icon {
    font-size: calc(17rem/16);
    padding-right: calc(10rem/16);
}
.nav-tabs--outline-primary .nav-link.active, .nav-tabs--outline-primary .nav-link:hover {
    background-color: var(--color-primary);
    color:#fff;
}