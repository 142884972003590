.contact-teaser__embed {
    padding-top: 172%;
    @media screen and (max-width: 767px) {
        padding-top: 70%;
    }
}
.contact-teaser {
    background-color:#fff;
    box-shadow: 0 calc(15rem/16) calc(30rem/16) 0 rgba(31,31,31,0.05);
    margin-bottom: calc(30rem/16);
    margin-top: calc(10rem/16);
    font-size: calc(14rem/16);
}
.contact-teaser__content {
    width: 100%;
    padding: calc(15rem/16) calc(24rem/16);
}
.contact-teaser__contact-link {
    color: var(--color-primary);
    display:block;
    margin-bottom: calc(7rem/16);
}
.contact-teaser__subtitle {
    color: var(--color-dark-grey);
}
.contact-teaser__title {
    margin-bottom: calc(15rem/16);
}
.contact-teaser__address {
    line-height:1.4;
    margin-bottom: calc(15rem/16);
}
.contact-teaser__contact-list-title {
    line-height:1;
    font-size: calc(12rem/16);
    color: var(--color-dark-grey);
}