.form-group-datepicker__input, .form-group-datepicker__input[readonly] {
    background-color: transparent;
}
.form-group-datepicker:before {
    position:absolute;
    pointer-events:none;
    content: var(--icon-calendar);
    display:block;
    font-family: 'iconfont';
    speak: none;
    font-style: normal;
    right: 0;
    bottom:calc(5rem/16);
}
.form-group-datepicker {
    position: relative;
}