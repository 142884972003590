.video-js .vjs-big-play-button {
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    top: 0;
    background-color:transparent;
    border:none;
    font-size: calc(60rem/16);
    color: rgba(255,255,255,0.9);
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: var(--icon-play);
    font-family:'iconfont';
}
.video-js .vjs-big-play-button:focus, .video-js:hover .vjs-big-play-button {
    background-color:transparent;
    background:none;
}