.dealer-item {
    padding: calc(20rem/16) 0;
    border-top: 1px solid var(--color-grey);
}

.dealer-item--border-bottom {
    border-bottom: 1px solid var(--color-grey);
}

.dealer-item--no-border-top {
    border-top: none;
}

.dealer-item__title {
    font-family: var(--font-default-bold);
    margin-bottom: calc(10rem/16);
}

.dealer-item__radio .custom-radio {
    padding-left: calc(28rem/16);
}