.location-teaser {
    position: relative;
    padding: calc(20rem / 16) calc(10rem / 16);
    box-shadow: 0 calc(15rem / 16) calc(30rem / 16) 0 rgba(31,31,31,0.05);
    @media screen and (min-width: 1200px) {
        padding: calc(25rem / 16) calc(40rem / 16);
    }
}
.location-teaser:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(6rem / 16);
    background: var(--color-primary);
    transform: scaleY(0);
    transition: transform 200ms ease-in-out;
    transform-origin: top;
}
.location-teaser.is-active:before {
    transform: scaleY(1);
}
.location-teaser__title {
    font-size: calc(14rem / 16);
}
.location-teaser__link {
    font-size: calc(13rem / 16);
}