.register-form__step-indicator {
    display: flex;
    align-items: center;
    margin-bottom: calc(30rem/16);
}

.register-form__step-indicator-item {
    display: flex;
    align-items: center;
    column-gap: calc(10rem/16);
}

.register-form__step-indicator-item.is-active {
    border-bottom: 1px solid var(--color-primary);
}

.register-form__step-indicator-number {
    font-family: var(--font-default-bold);
    color: white;
    background-color: var(--color-primary);
    width: calc(30rem/16);
    height: calc(30rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    flex-shrink: 0;
    @media screen and (min-width: 768px) {
        width: calc(40rem/16);
        height: calc(40rem/16);
    }
}

.register-form__step-indicator-text {
    font-family: var(--font-default-bold);
    padding-right: calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
    @media screen and (min-width: 768px) {
        padding-right: calc(30rem/16);
    }
}

.register-form__step-indicator-item.is-active .register-form__step-indicator-text {
    color: var(--color-primary);
}