.language-dropdown {

}
.language-dropdown__btn {
    background-color: white;
    color: var(--color-primary);
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: calc(12rem/16);
    column-gap: calc(2rem/16);
    &:hover, &:focus, &:active {
        background-color: white;
        color: var(--color-primary);
        box-shadow: none;
    }
    &::after {
        font-family: iconfont;
        content: var(--icon-chevron-up);
        border: none;
        transform: rotate(180deg);
        color: var(--color-primary);
    }
}
.show .language-dropdown__btn {
    background-color: white !important;
    color: var(--color-primary) !important;
}

.language-dropdown__btn--text {

}

.language-dropdown__menu {
    z-index: 10000;
    font-size: calc(12rem/16);
}

.language-dropdown__dropdown-item {
    padding: calc(4rem/16) calc(12rem/16);
}