.hero__embed--small {
    padding-top: calc(45 / 192 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: calc(155 / 320 * 100%);
    }
}

.hero__embed--extra-small {
    padding-top: calc(25 / 192 * 100%);
    @media screen and (max-width: 767px) {
        padding-top: calc(155 / 320 * 100%);
    }
}

.hero {
    position: relative;
}
.hero:after {
    content: "";
    position: absolute;
    bottom: calc(20rem / 16);
    width: 80%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
    height: calc(28rem / 16);
    display: block;
    box-shadow: 0 0.125rem calc(50rem / 16) 0 rgba(31, 31, 31, .25);
}
.hero__content {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}
.hero__content:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 60%;
    background: linear-gradient(270deg,hsl(0, 0%, 100%),hsla(0,0%,100%,0));
    right: 0;
    @media screen and (max-width: 1199px) {
        width: 100%;
        background: linear-gradient(270deg,hsl(0, 0%, 100%),hsla(0,0%,100%,0.5));
    }
}

/* Product Hero */
.hero--product{
    position: relative;
}
.hero--product .hero__headline {
    @media screen and (min-width:768px) and (max-width: 1200px) {
        font-size: 1.75rem;
        line-height: 1.1;
        bottom: 0;
    }
}
.hero--product:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    right: 50%;
    z-index: 1;
    height: 100%;
    background: linear-gradient(90deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0) 100%);
}
.hero__headline{
    z-index: 1;
    position: absolute;
    bottom: clamp(calc(10rem/16), 2vw ,calc(70rem/16));
    left: calc(15rem/16);
    max-width: calc(400rem/16);
    @media screen and (min-width: 1501px){
        left: calc( (100vw - 1470px) / 2);
    }
    @media screen and (max-width: 767px) {
        bottom: calc(10rem/16);
    }
}
.hero__breadcrumbs {
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
    @media screen and (min-width: 1501px){
        left: calc( (100vw - 1500px) / 2);
    }
}
.hero__claim {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background-color: hsla(0,0%,100%,0.7);
    right: calc(15rem / 16);
    @media screen and (min-width: 768px) and (max-width: 1500px){
        line-height: 1.2;
    }
    @media screen and (min-width: 768px) {
        max-width: calc(400rem/16);
        padding: clamp(calc(12rem/16), 2.25vw, calc(38rem/16));
    }
    @media screen and (min-width: 1501px){
        max-width: calc(480rem/16);
        right: calc( (100vw - 1500px) / 2);
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.hero__claim__arrow-top,
.hero__claim__arrow-bottom {
    position: absolute;
    width: calc(50rem/16);
    @media screen and (min-width:768px){
        width: clamp(calc(24rem/16), 4vw, calc(80rem/16));
    }
}

.hero__claim__arrow-top {
    left: 0;
    top: 0;
}
.hero__claim__arrow-bottom {
    right: 0;
    bottom: 0;
}
