.img-text-teaser__embed {
    padding-top: calc(308/356 * 100%);
}
.img-text-teaser__body {
    padding: calc(20rem/16) calc(34rem/16) calc(30rem/16);
    background-color:#fff;
    margin-bottom: calc(50rem/16);
    @media screen and (max-width: 1199px) {
        padding: calc(10rem/16);
    }
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
    }
}
.img-text-teaser__body .btn {
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding: calc(10rem/16);
    }
}