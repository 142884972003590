
.portal-hero-24__teaser-wrapper {
    display: grid;
    gap: calc(2rem/16);
    margin-top: calc(2rem/16);
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, max(45dvh, calc(424rem/16)));

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, max(45dvh, calc(424rem/16)));
    }

    @media screen and (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: max(70dvh, calc(848rem/16));
    }
}

.portal-hero-24__teaser-wrapper--thirds {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, max(45dvh, calc(424rem/16)));

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(3, max(45dvh, calc(424rem/16)));
    }

    @media screen and (min-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: max(70dvh, calc(848rem/16));
    }
}

.portal-24__search-grid {
    display: grid;
    gap: calc(2rem/16);
    margin-top: calc(2rem/16);
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, max(45dvh, calc(424rem/16)));
    overflow: visible;

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, max(45dvh, calc(424rem/16)));
    }

    @media screen and (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows:  max(25vh, calc(450rem/16))
    }
}

.portal-hero-24__teaser {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    }
}

.portal-hero-24__teaser-wide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    grid-column: 1 / span 1;
    padding-left: 2rem;
    padding-right: 2rem;
    overflow: visible;

    @media screen and (min-width: 768px) {
        grid-column: 1 / span 2;
        padding-left: unset;
        padding-right: unset;
    }

    @media screen and (min-width: 992px) {
        grid-column: 1 / span 4;
    }

    position: relative;

    &:has(.portal-24__search-background)::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    }
}

.portal-hero-24__teaser-wide.portal-hero-24__teaser-wide--no-backdrop-shadow::after {
    content: unset;
}

.portal-hero-24__teaser-content {
    width: 100%;
    position: absolute;
    top: 0;
    display: grid;
    grid-template-rows: 100px auto auto 1fr;
    padding-top: calc(60rem/16);
    justify-items: center;
    flex-direction: column;
    align-items: start;
    gap: calc(10rem/16);
    padding-left: 2rem;
    padding-right: 2rem;
    height: 100%;
    z-index: 1;
    @media screen and (min-width: 768px) {
        grid-template-rows: 100px 90px 50px 1fr;
        padding-top: calc(40rem/16);
    }
    @media screen and (min-width: 992px) {
        grid-template-rows: 100px 130px 80px 1fr;
        padding-top: clamp(1rem, 15.1vw, calc(270rem/16));
    }
    @media screen and (min-width: 1200px) {
        grid-template-rows: 100px 120px 60px 1fr;
    }
    @media screen and (min-width: 1501px) {
        grid-template-rows: 100px 90px 60px 1fr;
    }
}

.portal-hero-24__teaser-title {
    font-size: calc(30rem/16);
    font-family: var(--font-default);
    text-align: center;
    line-height: 125%;
    color: white;
    margin-bottom: 0rem;
    margin-top: .75rem;
    letter-spacing: calc(1.75rem/16);
}

.portal-hero-24__teaser-subtitle {
    font-size: calc(16rem/16);
    font-family: var(--font-default);
    text-align: center;
    line-height: 125%;
    color: white;
    margin-bottom: .75rem;
}

.portal-hero-24__search {
    z-index: 1;
    min-width: min(calc(420rem / 16), 40vw);
    padding: 1rem;
    padding-top: 1.25rem;
    padding-bottom: 0;
}

.portal-hero-24__search--text-button {
    box-shadow: 0 0.25rem calc(40rem / 16) 0rem rgba(31, 31, 31, .125);
    border: unset;
    width: 100%;

    @media screen and (min-width: 768px) {
        min-width: min(calc(600rem/16), 40vw);
        width: unset;
    }
}

.portal-hero-24__search-input-wrapper {
    display: flex;
    align-items: end;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
}

.portal-hero-24__search-button {
    font-size: 1.25rem;
    display: flex;
    aspect-ratio: 1;
    align-items: center;
    padding: .5rem;
}
.portal-hero-24__search-button--text {
    aspect-ratio: unset;
    font-size: .75rem;
    letter-spacing: calc(1.75rem/16);
    padding: .75rem 1.25rem;
}

.portal-hero-24__search-form-control {
    flex-grow: 1;
    width: 100%;
}

.portal-hero-24__search-form-control,
.portal-hero-24__search-button {
    margin-bottom: 1.5rem;
}

.portal-hero-24__search-headline {
    font-size: calc(26rem/16);
    letter-spacing: calc(1.75rem/16);
    margin-bottom: 0;
    text-align: center;
}
.portal-hero-24__search-headline.portal-hero-24__search-headline--within-card {
    font-size: calc(24rem/16);
    margin-top: 1rem;
}

.portal-hero-24__search-subtitle {
    font-size: calc(16rem/16);
    margin-bottom: 1.75rem;
    text-align: center;
}