.product-slider__arrow-container {
    position:absolute;
    right:0;
    display:flex;
    top: calc(-51rem/16);
    @media screen and (max-width: 767px) {
        display:flex;
        justify-content: center;
    }
}

.product-slider__arrow-container.product-slider__arrow-container--has-link{
    @media screen and (max-width: 767px) {
        top: calc(-122rem/16);
        transform: translateX(50%);
        right: 50%;
    }
}
.product-slider__arrow-container .arrow-next {
    margin-left: 1.25rem;
    @media screen and (max-width: 767px) {
        margin-left: calc(10rem/16);
    }
}
.product-slider__item {
    padding-left: calc(15rem/16);
    padding-right: calc(15rem/16);
}
.product-slider-area {
    padding-bottom: calc(10rem/16);
    overflow: hidden;
}
.product-slider .slick-list {
    overflow:visible;
}
.product-slider .product-teaser {
    display: flex;
    flex-direction: column;
    flex-grow:1;
}
.product-slider .product-teaser__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: auto;
}

.product-slider {
    margin-bottom: calc(51rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(51rem/16);
    }
}
.product-slider.product-slider--has-link {
    margin-bottom: calc(122rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(63rem/16);
    }
}

.product-slider__link{
    position: absolute;
    left: 50%;
    top: calc(-51rem/16);

    transform: translateX(-50%);

    @media screen and (max-width: 767px){
        display: flex;
        justify-content: center;
        align-items: center;
    }
}