:root {
    --nav-height-mobile: calc(60rem/16);
}
@media screen and (max-width: 767px) {
    .nav-wrapper {
        height: var(--nav-height-mobile);
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .main-nav {
        height: var(--nav-height-mobile);
        display: flex;
        align-items: center;
    }
    .main-nav__brand-img {
        height: calc(27rem / 16);
        padding-left: calc(10rem/16);
        position: relative;
    }
    .main-nav__collapse {
        display: none;
        padding: calc(20rem/16) calc(40rem/16);
    }
    .main-nav__close {
        display: none;
    }
    .main-nav__toggler {
        padding-left: calc(10rem/16);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }
    .main-nav__toggler-bar {
        width: calc(27rem/16);
        height: calc(2rem/16);
        background-color: var(--color-primary);
        display:block;
        border-radius: calc(10rem/16);
        transition: opacity 0.3s ease, transform 0.3s ease;
    }
    .main-nav__toggler-bar + .main-nav__toggler-bar {
        margin-top: calc(5rem/16);
    }
    .main-nav__toggler-bar:nth-of-type(2) {
        width: calc(22rem/16);
    }
    .is-open .main-nav__toggler-bar:nth-of-type(1) {
        transform: translateX(-5px)  rotate(45deg) translateX(10px);
    }
    .is-open .main-nav__toggler-bar:nth-of-type(2) {
        opacity:0;
    }
    .is-open .main-nav__toggler-bar:nth-of-type(3) {
        transform: translateX(-5px)  rotate( -45deg) translateX(11px) translateY(0px);
    }
    .main-nav__toggler-text {
        text-transform:uppercase;
        font-size: calc(8rem/16);
        padding-top: calc(8rem/16);
    }
    .main-nav__toggle-text--closed {
        opacity:0;
        display:none;
    }
    .is-open .main-nav__toggler-text {
        opacity:0;
        display:none;
    }
    .is-open .main-nav__toggle-text--closed {
        opacity:1;
        display:block;
    }
    .main-nav__search-btn {
        position: relative;
        color: var(--color-primary);
        padding:0;
        width: auto;
        height: auto;
        background-color:transparent;
        font-size: calc(19rem/16);
        display:block;
        border:none;
        flex-direction: column;
        margin-right: calc(10rem/16);
    }
    .main-nav__search-btn-text {
        display:block;
        text-transform:uppercase;
        font-size: calc(8rem/16);
        color: var(--color-text-default);
        padding-top: calc(5rem/16);
    }
    .is-open .main-nav__collapse {
        position: fixed;
        top: var(--nav-height-mobile);
        width:100%;
        height: calc(100% - var(--nav-height-mobile));
        background-color:#fff;
        display:flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .main-nav__nav-link {
        text-align:left;
        padding: calc(15rem/16) 0;
        font-size: calc(16rem/16);
    }
    .main-nav__nav-item--has-subnav .main-nav__nav-link:after {
        content:var(--icon-arrow-long);
        font-family:'iconfont';
        font-size: calc(11rem/16);
        padding-left: calc(10rem/16);
        color: var(--color-primary);
    }
    .main-nav__subsub-nav {
        position: fixed;
        top:0;
        visibility:hidden;
        background-color:#fff;
        z-index: 99;
        left:0;
    }
    .is-open > .main-nav__sub-nav {
        top: var(--nav-height-mobile);
        position:fixed;
        visibility:visible;
        height:calc(100%  - var(--nav-height-mobile));
        padding: calc(20rem/16) calc(40rem/16);
    }
    .is-open > .main-nav__subsub-nav {
        position:fixed;
        visibility:visible;
        height:100%;
        top:0;
        padding: calc(20rem/16) calc(40rem/16);
    }
    .main-nav__breadcrumb {
        font-size: calc(16rem/16);
        font-family: var(--font-default-bold);
        letter-spacing: calc(0.3rem/16);
    }
    .main-nav__breadcrumb:after {
        content:'';
        width: calc(26rem/16);
        display:block;
        height: calc(1rem/16);
        background-color: var(--color-primary);
        margin: calc(10rem/16) 0 calc(10rem/16);
    }
    .main-nav__sub-nav-item {
        font-size: calc(14rem/16);
    }
    .main-nav__sub-nav-item:after {
        width: calc(15rem/16);
    }
    .main-nav__back-btn {
        position:fixed;
        bottom:0;
        left:0;
        right:0;
        display:block;
        width:100%;
    }
    .main-nav.is-open:before {
        box-shadow: 0 calc(2rem/16) calc(30rem/16) 0 rgba(0,0,0,0.2);
        content: '';
        position: absolute;
        z-index: 1;
        width: 100%;
        height: calc(60rem/16);
        margin: 0 auto;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
    }
}