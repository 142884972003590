.product-features {
    position: relative;
    background-color: #3D4B5605;
    padding: calc(30rem/16) calc(20rem/16);
    overflow-x: hidden;
    @media screen and (min-width:768px){
        padding: calc(100rem/16);
    }
}

.product-features__arrow-top,
.product-features__arrow-bottom {
    position: absolute;
    width: calc(50rem/16);
    @media screen and (min-width:768px){
        width: calc(80rem/16);
    }
}

.product-features__arrow-top {
    left: 0;
    top: 0;
}
.product-features__arrow-bottom {
    right: 0;
    bottom: 0;
}
