@font-face {
    font-family: 'HelveticaNeue-light';
    src: url('/static/fonts/helvetica-lt.woff2') format('woff2'),
    url('/static/fonts/helvetica-lt.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue-bold';
    src: url('/static/fonts/helvetica-bold.woff2') format('woff2'),
    url('/static/fonts/helvetica-bold.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}
