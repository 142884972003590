.cart-area__article-items {
    display: flex;
    flex-direction: column;
    row-gap: calc(10rem/16);
    margin-top: calc(20rem/16);
}

.cart-area__article-items-legend {
    display: flex;
    justify-content: space-between;
}

.cart-area__download-buttons {
    display: flex;
    column-gap: calc(40rem/16);
    margin-bottom: calc(20rem/16);
}

.cart-area__download-link {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    text-transform: uppercase;
    column-gap: calc(16rem/16);
    line-height: 1;
    font-size: calc(13rem/16);
}

.cart-area__download-link .icon {
    font-size: calc(24rem/16);
}