.news-teaser-slider__wrapper {
    position:relative;
}
.news-teaser-slider {
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        margin-bottom: calc(10rem/16);
    }
}
.news-teaser-slider__arrow-container {
    position: absolute;
    top: 0;
    margin-top: 46%;
    @media screen and (max-width: 1199px) {
        position: relative;
        margin-top:0;
        text-align:center;
    }
}
.news-teaser-slider__arrow-container .slider__arrow {
    display: inline-flex;
}
.news-teaser-slider__arrow-container .arrow-next {
    margin-left: calc(20rem/16);
}
.news-teaser__slider-count {
    margin-top: calc(80rem/16);
}
.news-teaser-slider__item {
    position: relative;
}