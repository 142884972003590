.accordion-header {
    border-top: 1px solid rgba(61,75,86,0.3);
    padding: calc(15rem/16) calc(5rem/16);
}
.accordion {
    border-bottom: 1px solid rgba(61,75,86,0.3);
}
.accordion__toggle-icon {
    color: var(--color-primary);
    transform: rotate(45deg);
    transition: transform 0.2s ease;
}
.collapsed .accordion__toggle-icon {
    transform: rotate(0deg);
}
.accordion__header-link, .accordion__header-link:hover, .accordion__header-link:focus {
    color: var(--color-primary);
    transition: color 0.2s ease;
}
.accordion__header-link.collapsed {
    color: var(--color-text-default);
}
.accordion-body {
    padding: calc(10rem/16) calc(27rem/16) calc(20rem/16);
}