.text-teaser {
    box-shadow: 0 calc(15rem/16) calc(30rem/16) rgba(87,35,129,0.05);
    background-color:#fff;
    cursor:pointer;
    padding: calc(45rem/16) calc(28rem/16);
}
.text-teaser__info {
    color: var(--color-dark-grey);
}
.text-teaser__date {
    display:block;
}
.text-teaser__location {
    display:block;
}
.text-teaser__info:after {
    content: "";
    background-color: var(--color-primary);
    height: .0625rem;
    width: 4.625rem;
    display: block;
    margin-top: .9375rem;
    margin-bottom: .9375rem;
}
.text-teaser__title {
    font-size: calc(16rem/16);
    letter-spacing: calc(1rem/16);
    padding-top: calc(15rem/16);
    margin-bottom: calc(20rem/16);
    transition: color 0.2s ease;
}
.text-teaser__content {
    margin-bottom: calc(45rem/16);
}
.text-teaser:hover .text-teaser__title {
    color: var(--color-primary);
}