.meta-navbar__language-switch {
    padding-right: calc(30rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(40rem/16);
        padding-top: calc(20rem/16);
    }
}
.meta-navbar__language-switch-item {
    opacity: 0.4;
    font-size: calc(13rem/16);
    letter-spacing: calc(0.3rem/16);
    text-transform:uppercase;
    display:inline-flex;
}
.meta-navbar__language-switch-item.active {
    font-family: var(--font-default-bold);
    color: var(--color-primary);
    opacity:1;
    position: relative;
}
.meta-navbar__language-switch-item.active:after {
    content:'';
    position: absolute;
    height: calc(1rem/16);
    width:100%;
    bottom:0;
    left: 0;
    background-color: var(--color-primary);
}
.meta-navbar__language-switch-item + .meta-navbar__language-switch-item:before {
    content:'|';
    display:inline-flex;
    padding-left: calc(6rem/16);
    padding-right: calc(6rem/16);
}