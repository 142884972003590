.info-box {
    padding: calc(30rem/16);
    position:relative;
    background-color:#fff;
    font-size: calc(16rem/16);
    letter-spacing: calc(1rem/16);
}
.info-box:after {
    box-shadow: 0 0.125rem 3.125rem 0 rgba(31,31,31,.25);
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 74%;
    margin: 0 auto;
    height: 88%;
    left: 0;
    right: 0;
}
.info-box__label {
    line-height:1;
    color: var(--color-dark-grey);
    font-size: calc(12rem/16);
    letter-spacing:0;
}
.info-box__item {
    margin-bottom: calc(10rem/16);
}
.info-box__item:last-of-type {
    margin-bottom:0;
}