.text-teaser-slider .slick-list {
    overflow: visible; /* needed for box-shadow */
}
.text-teaser-slider__arrow-container .arrow-next {
    margin-left: calc(20rem/16);
}
.text-teaser-slider__arrow-container .slider__slide-count {
    @media screen and (min-width: 768px) {
        width: calc(146rem/16);
        margin-left: auto;
        display: flex;
        text-align: center;
        justify-content: center;
    }
}