html {
    font-size: 16px;

    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(14 / (991 / 100) * 1.3vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
    line-height: var(--line-height-default);
}
.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
    @media screen and (max-width: 767px) {
        hyphens: auto;
    }
}

h1, .h1 {
    font-size: calc(60rem/16);
    letter-spacing: calc(1.71rem/16);
    line-height: calc(70/60);
    font-weight: normal;
    @media screen and (max-width: 1199px) {
        font-size: calc(32rem/16);
        line-height: calc(38/32);
    }

}
h2, .h2 {
    font-size: calc(30rem/16);
    line-height: calc(45/30);
    letter-spacing: calc(4rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
        letter-spacing: calc(3rem/16);
    }
}
h3, .h3 {
    font-size: calc(24rem/16);
    line-height: calc(28/24);
    letter-spacing: calc(1.71rem/16);
}
h4, .h4 {
    font-size: calc(18rem/16);
    letter-spacing: calc(1.14rem/16);
    line-height: calc(25/18);
}
h5, .h5 {
    font-size: calc(16rem/16);
    letter-spacing: calc(1.14rem/16);
    line-height: calc(25/16);
}
h6, .h6 {
    font-size: calc(14rem/16);
}